<template>
  <div id="app">
    <Header />
    <b-container
      ref="containerContent"
      fluid
      id="content"
      class="content"
      :class="
        $store.state.showCadastroPf !== false
          ? 'contentPf'
          : $store.state.showCadastroPj !== false
          ? 'contentPj'
          : $store.state.showMsgSuccess !== false
          ? 'contentSuccess'
          : ''
      "
    >
      <b-row class="main-row">
        <b-col cols="12">
          <b-modal
            class="box-login-select float-right"
            title="Cadastro  - Novos Franqueados"
            id="box-login-select"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            hide-backdrop
            content-class="shadow"
          >
            <div
              class="modalInput"
              style="display: flex; flex-direction: column; width: 100%"
            >
              <b-form-input
                class="m-0"
                placeholder="Digite CPF para PF e CNPJ para PJ."
                maxlength="14"
                id="codCadastro"
                v-model="validationAuth"
                v-on:keyup.enter="validarCadastro()"
              ></b-form-input>
              <b-form-input
                class="m-0"
                v-on:keyup.enter="validarCadastro()"
                placeholder="Digite o token de cadastro"
                maxlength="14"
                id="codCadastro"
                v-model="tokenAuth"
              ></b-form-input>
            </div>
            <template #modal-footer>
              <b-button
                size="sm"
                variant="success"
                style="width: 100%; margin: 0px 25%; padding: 5px"
                @click="validarCadastro()"
                v-on:keyup.enter="validarCadastro()"
              >
                <span style="font-weight: lighter">Entrar</span>
              </b-button>
              <!-- <b-button size="sm" variant="danger" @click="cancelarCadastro();">
                Cancelar
              </b-button> -->
            </template>
          </b-modal>
        </b-col>
      </b-row>

      <!-- <b-col class="box-cadastros" cols="12" offset-lg="4" lg="8">
          <div> -->
      <b-row>
        <CadastroPj
          v-if="
            $store.state.showCadastroPj === true &&
            $store.state.showCadastroPf !== true
          "
        />

        <CadastroPf
          v-if="
            $store.state.showCadastroPf === true &&
            $store.state.showCadastroPj !== true
          "
        />
      </b-row>
      <!-- </div>
        </b-col> -->

      <Sucesso
        v-show="
          $store.state.showMsgSuccess === true &&
          $store.state.showCadastroPj !== true &&
          $store.state.showCadastroPf !== true
        "
      />
    </b-container>
    <Footer
      class="footer-interno"
      v-if="
        $store.state.showCadastroPf === false &&
        $store.state.showCadastroPj === false
      "
    />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import CadastroPj from "./components/cadastroPj.vue";
import CadastroPf from "./components/cadastroPf.vue";
const { cpf, cnpj } = require("cpf-cnpj-validator");
import { mapState } from "vuex";
import axios from "axios";
import { baseApiUrl } from "./global";
import Sucesso from "./components/Sucesso.vue";

export default {
  name: "App",
  components: { Header, Footer, CadastroPj, CadastroPf, Sucesso },
  data: function () {
    return {
      cepFranquia: "",
      cepEndereco: "",
      enderecoPf: "",
      numCasa: "",
      complementoCasa: "",
      bairroPf: "",
      cidadePf: "",
      emailPf: "",
      nomePf: "",
      cpf: "",
      rg: "",
      orgaoExp: "",
      profissao: "",
      nacionalidade: "",
      estadoCivil: "",
      rComunhaoBens: "",
      telefonePf: "",
      showModal: false,
      validationAuth: "",
      tokenAuth: "",
      validCpf: null,
      validCnpj: null,
      newAuthMsg: false,
    };
  },
  methods: {
    grabSuccess() {
      return this.$store.state.showMsgSuccess;
    },
    validarCadastro() {
      this.validCpf = cpf.isValid(cpf.format(this.validationAuth));
      this.validCnpj = cnpj.isValid(cnpj.format(this.validationAuth));
      console.log("cpfvalid", this.validCpf, "cnpjvalid", this.validCnpj);

      if (this.validCpf === true && this.validCnpj === false) {
        console.log("validcpf", this.validCpf, baseApiUrl);

        axios
          .post(`${baseApiUrl}/token/validateToken`, {
            doc: this.validationAuth,
            token: this.tokenAuth,
          })
          .then((e) => {
            console.log("validate token axios then (e)", e);
            if (e.data.errorcode === "1" || e.data.errorcode === 1) {
              console.log(e, e.data, e.data.errorcode, "edata, edataerrorcode");
              this.newAuthMsg = true;
              this.$store.state.showCadastroPf = true;
              this.$store.state.showCadastroPj = false;
              console.log(e.data, "edata");
              localStorage.setItem("token", JSON.stringify(e.data));
              this.$store.commit("setValidacaoAuth", this.validationAuth);
              this.$bvModal.hide("box-login-select");
            } else {
              this.$toasted.show("Erro: Não Autorizado.", {
                theme: "bubble",
                position: "top-center",
                duration: 5000,
              });
            }
          })
          .catch((err) => {
            this.$toasted.show(err, {
              theme: "bubble",
              position: "top-center",
              duration: 5000,
            });
          });
      } else if (this.validCpf === false && this.validCnpj === false) {
        this.$toasted.show("Cpf / Cnpj Inválidos", {
          theme: "bubble",
          position: "top-center",
          duration: 5000,
        });
      } else if (this.validCpf === false && this.validCnpj === true) {
        console.log("cnpj true");
        axios
          .post(`${baseApiUrl}/token/validateToken`, {
            doc: this.validationAuth,
            token: this.tokenAuth,
          })
          .then((e) => {
            console.log(e);
            if (e.data.errorcode === "1" || e.data.errorcode === 1) {
              this.newAuthMsg = true;
              this.$store.state.showCadastroPf = false;
              this.$store.state.showCadastroPj = true;
              this.$store.commit("setValidacaoAuth", this.validationAuth);
              localStorage.setItem("token", JSON.stringify(e.data));
              this.$bvModal.hide("box-login-select");
            } else {
              this.$toasted.show("Erro: Não Autorizado. ", {
                theme: "bubble",
                position: "top-center",
                duration: 5000,
              });
            }
          })
          .catch((err) => {
            this.$toasted.show(err, {
              theme: "bubble",
              position: "top-center",
              duration: 5000,
            });
          });
      } else if (this.validCnpj === false && this.validCpf === false) {
        this.$toasted.show("erro: Não Autorizado. CPF ou CNPJ Inválidos.", {
          theme: "bubble",
          position: "top-center",
          duration: 5000,
        });
      }
    },
  },

  computed: {
    ...mapState([
      "validacaoAuth",
      "authMsg",
      "showCadastroPf",
      "showCadastroPj",
      "showMsgSuccess",
    ]),
  },
  mounted() {
    this.$bvModal.show("box-login-select");
  },
};
</script>

<style lang="scss">
.card {
  background-color: #f9f4f2 !important;
}

.box-cadastros {
  padding: 30px 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content {
  background: url("../src/assets/bg-pf.png"), no-repeat, center, center, center;
  min-height: calc(100vh - 36px);
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
}

.box-login-select {
  padding: 2.5%;

  img {
    max-width: 350px;
    opacity: 0.5;
  }
}
.main-row {
  min-height: fit-content;
}
#codCadastro {
  max-width: 100%;
  border-radius: 20px 20px 20px 20px;
  font-weight: 500;
  font-size: 1.25rem;
  margin: 8px 0px !important;
  background-color: #e8e8e8;
  font-size: 1rem;
  text-align: center;
  border: 0;
}
#box-login-select___BV_modal_body_ {
  display: flex;
}
.modal-dialog {
  top: 30%;
  color: #f2f2f2;
}
.modal-content {
  background-color: #f2f2f2f2 !important;
  color: #c5b6b4;
  max-width: 400px;
}
.modal-header {
  background-color: #af9187;
  h5 {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
}

.modal-footer {
  flex-wrap: nowrap !important;
  background-color: #f2f2f2;
  button {
    width: 50%;
    text-align: center;
  }
}
.contentPf {
  //background: url('../src/assets/bg-login.png'), no-repeat, center, center, center;

  min-height: 100vh;
  background: #f9f4f2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center;
}
.contentPj {
  /* background: url("../src/assets/bg-pj.png"), no-repeat, center, center, center;*/
  background: none;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.5);
}
.contentSuccess {
  /* background: url("../src/assets/bg-success.jpg"), no-repeat, center, center,*/
  //background: none;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center;
}
@font-face {
  font-family: "Effra_Std";

  src: url("../public/fonts/Effra_Std.ttf") format("ttf");
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
b,
a {
  font-family: "Effra_Std", sans-serif !important;
  font-weight: 300;
}
.footer-interno {
  position: fixed;
  /* height: 50px; */
  background: transparent;
  width: 100%;
  height: auto;
  bottom: 0px;
}
.footer-text {
  color: #604e48;
}

h2 {
  font-family: "Effra_Std", sans-serif;
  color: #897167 !important;
}
.modal-title {
  color: white;
}
.form-control.is-invalid {
  border-color: #f57424 !important;
}
h3 {
  font-size: 1.75rem;
  text-align: start;
  //color: #4C4C47;

  padding-bottom: 8px;
}
h2,
h4 {
  font-size: 2rem;
  text-align: start;
  font-family: "Effra_Std-bold";

  color: #4c4c47;
}
.form-label-custom {
  width: 100%;
  text-align: start;
  padding-left: 5px;
  font-family: "Effra_std";
  color: #897167;
  font-size: 16px;
}
.btnVoltar {
  background-color: #897167 !important;

  padding: 3px 0px !important;
  max-width: 150px;
  width: 100%;
}
.btnAvancar {
  background-color: #198754 !important;

  padding: 3px 0px !important;
  max-width: 150px;
  width: 100%;
}
.btnIncluir {
  background-color: #f58025 !important;

  padding: 3px 0px !important;
  max-width: 150px;
  width: 100%;
}
</style>
