<template>
  <div>
    <b-row style="min-height: 100vh">
      <b-col cols="3" style="background: #897167">
        <div class="asideVoltar">
          <b-button variant="outline" @click="voltar()"
            ><i class="fa fa-5x fa-arrow-left" style="color: white"></i>
            <p style="color: white">Voltar</p>
          </b-button>

          <!-- <b-button variant="outline" @click="voltar()">
            <i class="fa fa-5x fa-arrow-left" style="color: white"></i>
            <p style="color: white">Voltar</p>
          </b-button> -->
        </div>
      </b-col>
      <b-col cols="9">
        <b-form :novalidate="validate" class="form-controle">
          <h4 style="letter-spacing: 1.25px; color: #897167; margin-bottom: 3%">
            <b>Cadastro Pessoa Física -</b> Novos Franqueados
          </h4>

          <transition name="fade">
            <div
              border-variant="light"
              v-show="validationOne !== true && validacaoAuth !== ''"
            >
              <b-form-group>
                <b-form-row class="row-form">
                  <b-col cols="4">
                    <label class="form-label-custom" for="nome-completo"
                      >Nome completo</label
                    >

                    <b-form-input
                      id="nome-completo"
                      type="text"
                      v-model="identificacaoPf.nome"
                      oninput="this.value = this.value.toUpperCase()"
                      :state="identificacaoPf.nome !== ''"
                      :disabled="validationOne === true"
                      maxlength="29"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="4">
                    <label class="form-label-custom" for="email">Email</label>
                    <b-form-input
                      v-model="identificacaoPf.email"
                      type="email"
                      oninput="this.value = this.value.toUpperCase();"
                      :disabled="validationOne === true"
                      :state="identificacaoPf.email !== ''"
                      maxlength="29"
                      id="Email"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="profissao"
                      >Profissão</label
                    >
                    <b-form-input
                      id="profissao"
                      maxlength="30"
                      type="text"
                      oninput="this.value = this.value.toUpperCase()"
                      :state="identificacaoPf.profissao !== ''"
                      :disabled="validationOne === true"
                      v-model="identificacaoPf.profissao"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="CPF">CPF</label>
                    <b-form-input
                      id="CPF"
                      max
                      type="number"
                      required
                      :disabled="true"
                      :state="true"
                      maxlength="11"
                      v-model="$store.state.validacaoAuth"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="tel">Telefone</label>
                    <b-form-input
                      type="tel"
                      maxlength="15"
                      v-model="identificacaoPf.telefone"
                      required
                      :disabled="validationOne === true"
                      :state="identificacaoPf.telefone !== ''"
                      id="tel"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="nacionalidade"
                      >Nacionalidade</label
                    >
                    <b-form-select
                      value-field="name"
                      text-field="name"
                      type="text"
                      v-model="identificacaoPf.nacionalidade"
                      :options="$store.state.nacionalidadeSelect"
                      class="form-control"
                      id="nacionalidade"
                      :state="identificacaoPf.nacionalidade !== ''"
                      oninput="this.value = this.value.toUpperCase()"
                    >
                      <template #first>
                        <b-form-select-option v-bind:value="1" selected
                          >-- Nacionalidade. --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-col>

                  <div
                    v-show="identificacaoPf.nacionalidade === 'ESTRANGEIRA'"
                    style="width: 100%; display: flex; flex-wrap: wrap"
                  >
                    <b-col cols="6">
                      <label class="form-label-custom" for="pais-origem"
                        >Páis Origem</label
                      >
                      <b-form-input
                        type="text"
                        oninput="this.value = this.value.toUpperCase();"
                        id="pais-origem"
                        v-model="identificacaoPf.paisOrigem"
                        :state="
                          identificacaoPf.paisOrigem !== null ? true : false
                        "
                      >
                      </b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label class="form-label-custom" for="doc-legal"
                        >Nº Documento Legal</label
                      >
                      <b-form-input
                        type="text"
                        oninput="this.value = this.value.toUpperCase();"
                        id="doc-legal"
                        v-model="identificacaoPf.docLegal"
                        :state="
                          identificacaoPf.docLegal !== null ? true : false
                        "
                      >
                      </b-form-input>
                    </b-col>
                  </div>

                  <div
                    v-show="identificacaoPf.nacionalidade === 'BRASILEIRA'"
                    style="width: 100%; display: flex; flex-wrap: wrap"
                  >
                    <b-col cols="8">
                      <label class="form-label-custom" for="RG">RG</label>
                      <b-form-input
                        id="RG"
                        type="number"
                        required
                        :disabled="validationOne === true"
                        :state="identificacaoPf.rg !== ''"
                        cols="4"
                        maxlength="15"
                        v-model="identificacaoPf.rg"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col cols="4">
                      <label class="form-label-custom" for="orgao-expeditor"
                        >Orgão Expedidor</label
                      >
                      <b-form-input
                        id="orgao-expeditor"
                        maxlenght="8"
                        type="text"
                        cols="4"
                        :disabled="validationOne === true"
                        v-model="identificacaoPf.orgaoExpeditor"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="identificacaoPf.orgaoExpeditor !== ''"
                      >
                      </b-form-input>
                    </b-col>
                  </div>

                  <b-col cols="5">
                    <label class="form-label-custom" for="CEP">CEP</label>
                    <b-form-input
                      ref="campoCep"
                      type="number"
                      id="CEP"
                      required
                      :disabled="validationOne === true"
                      :state="validCep !== ''"
                      v-model="validCep"
                      maxlength="10"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="1">
                    <b-button
                      variant="outline-success"
                      @click="achaEnd(validCep, 'ifPf1')"
                      style="margin-top: 40px"
                      ><i class="fa fa-search"></i
                    ></b-button>
                  </b-col>
                  <b-col cols="6">
                    <label class="form-label-custom" for="endereco"
                      >Endereço</label
                    >
                    <b-form-input
                      type="text"
                      :state="identificacaoPf.endereco !== '' ? true : false"
                      id="endereco"
                      v-model="identificacaoPf.endereco"
                      maxlength="50"
                      oninput="this.value = this.value.toUpperCase()"
                    >
                    </b-form-input>
                  </b-col>
                </b-form-row>
              </b-form-group>

              <b-form-group>
                <b-form-row class="row-form">
                  <b-col cols="4">
                    <label class="form-label-custom" for="bairro">Bairro</label>
                    <b-form-input
                      type="text"
                      oninput="this.value = this.value.toUpperCase()"
                      :state="identificacaoPf.bairro !== '' ? true : false"
                      id="bairro"
                      v-model="identificacaoPf.bairro"
                      maxlenght="35"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="form-label-custom" for="num">Num.</label>
                    <b-form-input
                      type="number"
                      maxlength="8"
                      :state="identificacaoPf.num !== '' ? true : false"
                      id="num"
                      v-model="identificacaoPf.num"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="form-label-custom" for="cidade">Cidade</label>
                    <b-form-input
                      type="text"
                      maxlength="35"
                      oninput="this.value = this.value.toUpperCase()"
                      :state="identificacaoPf.cidade !== '' ? true : false"
                      id="cidade"
                      v-model="identificacaoPf.cidade"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="form-label-custom" for="complemento"
                      >Complemento</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="30"
                      oninput="this.value = this.value.toUpperCase()"
                      :state="identificacaoPf.complemento !== ''"
                      id="complemento"
                      v-model="identificacaoPf.complemento"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <label class="form-label-custom" for="UF">UF</label>
                    <b-form-input
                      type="text"
                      maxlength="2"
                      oninput="this.value = this.value.toUpperCase()"
                      :state="identificacaoPf.uf !== '' ? true : false"
                      id="UF"
                      v-model="identificacaoPf.uf"
                    >
                    </b-form-input>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-col cols="12">
                <b-form-group>
                  <label class="form-label-custom" for="estado-civil"
                    >Estado Civil</label
                  >
                  <b-form-select
                    value-field="name"
                    id="estado-civil"
                    text-field="name"
                    class="form-control"
                    size="sm"
                    :options="$store.state.selectEstadoCivil"
                    v-model="identificacaoPf.estadoCivil"
                    @change="
                      test('estadoCivil', identificacaoPf.estadoCivilSelected)
                    "
                    :state="
                      identificacaoPf.estadoCivil !== '' ||
                      identificacaoPf.estadoCivil.length > 1
                    "
                  >
                    <template #first>
                      <b-form-select-option v-bind:value="1" selected
                        >-- Selecione uma opção. --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-button
                v-show="
                  identificacaoPf.estadoCivil !== 'CASADO' ||
                  identificacaoPf.estadoCivil !== 'UNIÃO ESTÁVEL'
                "
                class="btnAvancar"
                @click="proximo()"
                >Avançar</b-button
              >
            </div>
          </transition>

          <transition name="fade">
            <div
              v-show="
                (identificacaoPf.estadoCivil === 'CASADO' ||
                  identificacaoPf.estadoCivil === 'UNIÃO ESTÁVEL') &&
                validationOne !== true
              "
            >
              <h3>Dados Conjugue</h3>
              <b-form-group>
                <b-form-row class="row-form">
                  <b-col cols="12">
                    <label class="form-label-custom" for="nome-conjugue"
                      >Nome Conjugue</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="30"
                      oninput="this.value = this.value.toUpperCase()"
                      id="nome-conjugue"
                      v-model="identificacaoPf.nomeConjugue"
                      :state="identificacaoPf.nomeConjugue !== ''"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label class="form-label-custom" for="regime-bens"
                      >Regime Bens</label
                    >
                    <b-form-select
                      id="regime-bens"
                      value-field="name"
                      text-field="name"
                      class="form-control"
                      :options="$store.state.selectRegimeBens"
                      v-model="identificacaoPf.regimeBens"
                    >
                      <option value="1" disabled>
                        -- Selecione uma opção. --
                      </option>
                    </b-form-select>

                    <!-- <b-form-input oninput="this.value = this.value.toUpperCase()" type="text"
											<label class="form-label-custom" for="Re">Re</label>
                      
										</b-form-input> -->
                  </b-col>

                  <!-- <b-col cols="6">
                <label class="form-label-custom" for="Re">Re</label>
                
                  :state="identificacaoPf.regimeBens !== ''">
                </b-form-input>
              </b-col> -->

                  <b-col cols="6">
                    <label class="form-label-custom" for="tel-conjugue"
                      >Tel Conjugue</label
                    >
                    <b-form-input
                      type="tel"
                      maxlenght="15"
                      id="tel-conjugue"
                      v-model="identificacaoPf.TelConjugue"
                      :state="identificacaoPf.TelConjugue !== ''"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label class="form-label-custom" for="cpf-conjugue"
                      >CPF Conjugue</label
                    >
                    <b-form-input
                      type="number"
                      id="cpf-conjugue"
                      maxlength="11"
                      v-model="identificacaoPf.cpfConjugue"
                      :state="identificacaoPf.cpfConjugue !== ''"
                    >
                    </b-form-input>
                  </b-col>
                  <!-- <b-col cols="6">
                <label class="form-label-custom" for="Or">Or</label>
                
                  :state="identificacaoPf.conjugueOrgaoExpeditor !== ''">
                </b-form-input>
              </b-col> -->
                  <b-col cols="6">
                    <label class="form-label-custom" for="rg-conjugue"
                      >RG conjugue</label
                    >
                    <b-form-input
                      type="number"
                      id="rg-conjugue"
                      maxlength="15"
                      v-model="identificacaoPf.rgConjugue"
                      :state="identificacaoPf.rgConjugue !== ''"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="12">
                    <label class="form-label-custom" for="email-conjugue"
                      >Email</label
                    >
                    <b-form-input
                      type="email"
                      id="email-conjugue"
                      v-model="identificacaoPf.emailConjugue"
                      :state="
                        identificacaoPf.emailConjugue !== '' ? true : false
                      "
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label class="form-label-custom" for="profissao-conjugue"
                      >Profissão Conjugue</label
                    >
                    <b-form-input
                      type="text"
                      id="profissao-conjugue"
                      maxlength="30"
                      oninput="this.value = this.value.toUpperCase()"
                      v-model="identificacaoPf.profissaoConjugue"
                      :state="identificacaoPf.profissaoConjugue !== ''"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label
                      class="form-label-custom"
                      for="nacionalidade-conjugue"
                      >Nacionalidade Conjugue</label
                    >
                    <b-form-select
                      value-field="name"
                      id="nacionalidade-conjugue"
                      text-field="name"
                      class="form-control"
                      :options="$store.state.nacionalidadeSelect"
                      v-model="identificacaoPf.nacionalidadeConjugue"
                      :state="identificacaoPf.nacionalidadeConjugue !== ''"
                      oninput="this.value = this.value.toUpperCase()"
                    >
                      <template #first>
                        <b-form-select-option v-bind:value="1" disabled
                          >-- Selecione uma opção. --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-col>
                  <div
                    v-show="
                      identificacaoPf.nacionalidadeConjugue === 'ESTRANGEIRA'
                    "
                    style="width: 100%; display: flex; flex-wrap: wrap"
                  >
                    <b-col cols="6">
                      <label
                        class="form-label-custom"
                        for="pais-origem-conjugue"
                        >Páis Origem Conjugue</label
                      >
                      <b-form-input
                        type="text"
                        oninput="this.value = this.value.toUpperCase();"
                        id="pais-origem-conjugue"
                        v-model="identificacaoPf.conjuguePaisOrigem"
                        :state="
                          identificacaoPf.conjuguePaisOrigem !== null
                            ? true
                            : false
                        "
                      >
                      </b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label class="form-label-custom" for="doc-legal-conjugue"
                        >Nº Documento Legal</label
                      >
                      <b-form-input
                        type="text"
                        oninput="this.value = this.value.toUpperCase();"
                        id="doc-legal-conjugue"
                        v-model="identificacaoPf.conjugueDocLegal"
                        :state="
                          identificacaoPf.conjugueDocLegal !== null
                            ? true
                            : false
                        "
                      >
                      </b-form-input>
                    </b-col>
                  </div>
                </b-form-row>
              </b-form-group>
              <b-button
                v-show="
                  identificacaoPf.estadoCivil === 'CASADO' ||
                  identificacaoPf.estadoCivil === 'UNIÃO ESTÁVEL'
                "
                @click="proximo()"
                class="btnAvancar"
                >Avançar</b-button
              >
            </div>
          </transition>

          <transition name="fade">
            <div v-show="validationOne != false" style="min-height: 100vh">
              <b-form-group>
                <b-form-row class="row-form">
                  <b-col cols="12">
                    <h3>Sócios Administradores</h3>
                  </b-col>

                  <b-col cols="12">
                    <div
                      class="copy d-flex"
                      style="
                        text-align: start;
                        flex-direction: start;
                        justify-content: flex-start;
                      "
                    >
                      <b-form-checkbox
                        class="copiarDados"
                        style="margin: 5px 0px"
                        v-model="socios.copiar"
                        value="Copiar"
                        @change="copySocios()"
                        >Copiar Dados para Socios</b-form-checkbox
                      >
                      <!-- <b-button variant="outline-success" @click="copySocios()"
                  ><i class="fa fa-plus-circle"></i></b-button
                  > -->
                    </div>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="nome-socio"
                      >Nome Sócio</label
                    >
                    <b-form-input
                      maxlength="28"
                      type="text"
                      oninput="this.value = this.value.toUpperCase()"
                      id="nome-socio"
                      v-model="socios.nome"
                      :state="socios.nome !== '' || socios.nome.length > 1"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="4">
                    <label class="form-label-custom" for="cpf-socio"
                      >CPF Sócio</label
                    >
                    <b-form-input
                      type="number"
                      maxlength="11"
                      id="cpf-socio"
                      v-model="socios.cpf"
                      :state="socios.cpf !== '' || socios.cpf.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="rg-socio"
                      >RG Sócio</label
                    >
                    <b-form-input
                      type="number"
                      maxlength="15"
                      id="rg-socio"
                      v-model="socios.rg"
                      :state="socios.rg !== '' || socios.rg.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="5">
                    <label class="form-label-custom" for="cep-socio"
                      >CEP Sócio</label
                    >
                    <b-form-input
                      type="number"
                      maxlength="10"
                      id="cep-socio"
                      v-model="socios.cep"
                      :state="socios.cep !== '' || socios.cep.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="1">
                    <b-button
                      variant="outline-success"
                      @click="achaEnd(socios.cep, 'sociosPf')"
                      style="margin-top: 40px"
                      ><i class="fa fa-search"></i
                    ></b-button>
                  </b-col>

                  <b-col cols="6">
                    <label class="form-label-custom" for="nacionalidade-socio"
                      >Nacionalidade Sócio</label
                    >
                    <b-form-select
                      value-field="name"
                      text-field="name"
                      id="nacionalidade-socio"
                      type="text"
                      v-model="socios.nacionalidade"
                      :options="$store.state.nacionalidadeSelect"
                      class="form-control"
                      :state="socios.nacionalidade !== ''"
                      oninput="this.value = this.value.toUpperCase()"
                    >
                      <!-- <template #first> 

                    <b-form-select-option v-bind:value="0" selected disabled>-- Nacionalidade. --</b-form-select-option>
                  </template> -->
                      <option value="" disabled>
                        -- Selecione uma opção. --
                      </option>
                    </b-form-select>
                  </b-col>
                  <div
                    v-show="socios.nacionalidade === 'ESTRANGEIRA'"
                    style="width: 100%; display: flex; flex-wrap: wrap"
                  >
                    <b-col cols="6">
                      <label class="form-label-custom" for="pais-origem-socio"
                        >Páis Origem</label
                      >
                      <b-form-input
                        type="text"
                        oninput="this.value = this.value.toUpperCase();"
                        id="pais-origem-socio"
                        v-model="socios.paisOrigem"
                        :state="socios.paisOrigem !== null ? true : false"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label class="form-label-custom" for="doc-legal-socio"
                        >Nº Doc. Legal</label
                      >
                      <b-form-input
                        type="text"
                        oninput="this.value = this.value.toUpperCase();"
                        id="doc-legal-socio"
                        v-model="socios.docLegal"
                        :state="socios.docLegal !== null ? true : false"
                      >
                      </b-form-input>
                    </b-col>
                  </div>

                  <b-col cols="6">
                    <label class="form-label-custom" for="endereco-socios"
                      >Endereço Sócio</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="50"
                      oninput="this.value = this.value.toUpperCase()"
                      id="endereco-socios"
                      v-model="socios.endereco"
                      :state="
                        socios.endereco !== '' || socios.endereco.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="3">
                    <label
                      class="form-label-custom"
                      for="orgao-expeditor-socios"
                      >Orgão Expeditor</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="8"
                      oninput="this.value = this.value.toUpperCase()"
                      id="orgao-expeditor-socios"
                      v-model="socios.orgaoExpeditor"
                      :state="
                        socios.orgaoExpeditor !== '' ||
                        socios.orgaoExpeditor.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="3">
                    <label class="form-label-custom" for="profissao-socios"
                      >Profissão Sócio</label
                    >
                    <b-form-input
                      type="text"
                      maxlegnth="30"
                      oninput="this.value = this.value.toUpperCase()"
                      id="profissao-socios"
                      v-model="socios.profissao"
                      :state="
                        socios.profissao !== '' || socios.profissao.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="numero-socios"
                      >Num.</label
                    >
                    <b-form-input
                      type="number"
                      maxlength="8"
                      id="numero-socios"
                      v-model="socios.num"
                      :state="socios.num !== '' || socios.num.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="complemento-socios"
                      >Complemento</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="30"
                      oninput="this.value = this.value.toUpperCase()"
                      id="complemento-socios"
                      v-model="socios.complemento"
                      :state="
                        socios.complemento !== '' ||
                        socios.complemento.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="4">
                    <label class="form-label-custom" for="bairro-socios"
                      >Bairro</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="35"
                      oninput="this.value = this.value.toUpperCase()"
                      id="bairro-socios"
                      v-model="socios.bairro"
                      :state="socios.bairro !== '' || socios.bairro.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="5">
                    <label class="form-label-custom" for="cidade-socios"
                      >Cidade</label
                    >
                    <b-form-input
                      type="text"
                      maxlength="15"
                      oninput="this.value = this.value.toUpperCase()"
                      id="cidade-socios"
                      v-model="socios.cidade"
                      :state="socios.cidade !== '' || socios.cidade.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="2">
                    <label class="form-label-custom" for="uf-socios">UF</label>
                    <b-form-input
                      type="text"
                      maxlength="2"
                      oninput="this.value = this.value.toUpperCase()"
                      id="uf-socios"
                      v-model="socios.uf"
                      :state="socios.uf !== '' || socios.uf.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="5">
                    <label class="form-label-custom" for="tel-socios"
                      >Tel.</label
                    >
                    <b-form-input
                      type="tel"
                      maxlegnth="15"
                      id="tel-socios"
                      v-model="socios.telefone"
                      :state="
                        socios.telefone !== '' || socios.telefone.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <label class="form-label-custom" for="email-socios"
                      >Email</label
                    >
                    <b-form-input
                      type="email"
                      id="email-socios"
                      v-model="socios.email"
                      :state="socios.email !== '' ? true : false"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label class="form-label-custom" for="estado-civil-socios"
                      >Estado Civil</label
                    >
                    <b-form-select
                      value-field="name"
                      text-field="name"
                      id="estadocivil estado-civil-socios"
                      class="form-control"
                      style="margin-top: 10px"
                      :options="$store.state.selectEstadoCivil"
                      v-model="socios.estadoCivil"
                      :state="socios.estadoCivil.length > 1"
                    >
                      <!-- <template #first> 
                      <b-form-select-option v-bind:value="0" selected disabled>-- Selecione um opção. --</b-form-select-option>
                    </template> -->

                      <option value="" disabled>
                        -- Selecione uma opção. --
                      </option>
                    </b-form-select>
                  </b-col>

                  <div
                    v-show="
                      (socios.estadoCivil === 'CASADO' ||
                        socios.estadoCivil === 'UNIÃO ESTÁVEL') &&
                      validationOne != false
                    "
                  >
                    <h3>Dados Cônjugue Sócios Adm.</h3>
                    <b-form-group>
                      <b-form-row class="row-form">
                        <b-col cols="6">
                          <label
                            class="form-label-custom"
                            for="nome-conjugue-socios"
                            >Nome Conjugue</label
                          >
                          <b-form-input
                            type="text"
                            maxlegnth="30"
                            oninput="this.value = this.value.toUpperCase()"
                            id="nome-conjugue-socios"
                            v-model="socios.nomeConjugue"
                            :state="socios.nomeConjugue !== ''"
                          >
                          </b-form-input>
                        </b-col>

                        <b-col cols="3">
                          <label
                            class="form-label-custom"
                            for="cpf-conjugue-socios"
                            >CPF Conjugue</label
                          >
                          <b-form-input
                            type="number"
                            maxlength="11"
                            id="cpf-conjugue-socios"
                            v-model="socios.cpfConjugue"
                            :state="socios.cpfConjugue !== ''"
                          >
                          </b-form-input>
                        </b-col>

                        <b-col cols="3">
                          <label
                            class="form-label-custom"
                            for="rg-conjugue-socios"
                            >RG Conjugue</label
                          >
                          <b-form-input
                            type="number"
                            maxlenght="15"
                            id="rg-conjugue-socios"
                            v-model="socios.rgConjugue"
                            :state="socios.rgConjugue !== ''"
                          >
                          </b-form-input>
                        </b-col>

                        <b-col cols="12">
                          <label
                            class="form-label-custom"
                            for="email-conjugue-socios"
                            >Email Conjugue</label
                          >
                          <b-form-input
                            type="email"
                            id="email-conjugue-socios"
                            v-model="socios.emailConjugue"
                            :state="socios.emailConjugue !== '' ? true : false"
                          ></b-form-input>
                        </b-col>

                        <b-col cols="4">
                          <label
                            class="form-label-custom"
                            for="regime-bens-socios"
                            >Regime Bens</label
                          >
                          <b-form-select
                            value-field="name"
                            id="regime-bens-socios"
                            text-field="name"
                            class="form-control"
                            :options="$store.state.selectRegimeBens"
                            v-model="socios.conjugueRegimeBens"
                            :state="socios.conjugueRegimeBens !== ''"
                            @change="test('regimeBens', arg)"
                          >
                            <template #first>
                              <b-form-select-option v-bind:value="1" selected
                                >-- Selecione uma opção.
                                --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-col>

                        <b-col cols="4">
                          <label
                            class="form-label-custom"
                            for="profissao-conjugue-socios"
                            >Profissão Conjugue</label
                          >
                          <b-form-input
                            maxlenght="30"
                            type="text"
                            oninput="this.value = this.value.toUpperCase()"
                            id="profissao-conjugue-socios"
                            v-model="socios.profissaoConjugue"
                            :state="socios.profissaoConjugue !== ''"
                          >
                          </b-form-input>
                        </b-col>

                        <b-col cols="4">
                          <label
                            class="form-label-custom"
                            for="nacionalidade-conjugue-socios"
                            >Nacionalidade Conjugue</label
                          >
                          <b-form-select
                            value-field="name"
                            text-field="name"
                            class="form-control"
                            :options="$store.state.nacionalidadeSelect"
                            oninput="this.value = this.value.toUpperCase()"
                            id="nacionalidade-conjugue-socios"
                            v-model="socios.nacionalidadeConjugue"
                            :state="socios.nacionalidadeConjugue !== ''"
                          >
                            <template #first>
                              <b-form-select-option v-bind:value="1" selected
                                >-- Nacionalidade. --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-col>

                        <div
                          v-show="
                            socios.nacionalidadeConjugue === 'ESTRANGEIRA'
                          "
                          style="
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            margin: 0px 0px 24px 0px;
                          "
                        >
                          <b-col cols="6">
                            <label
                              class="form-label-custom"
                              for="pais-conjugue-socios"
                              >País Origem</label
                            >
                            <b-form-input
                              type="text"
                              oninput="this.value = this.value.toUpperCase();"
                              id="pais-conjugue-socios"
                              v-model="socios.conjuguePaisOrigem"
                              :state="
                                socios.conjuguePaisOrigem !== null
                                  ? true
                                  : false
                              "
                            >
                            </b-form-input>
                          </b-col>
                          <b-col cols="6">
                            <label
                              class="form-label-custom"
                              for="doc-conjugue-socios"
                              >Nº Doc. Legal</label
                            >
                            <b-form-input
                              type="text"
                              oninput="this.value = this.value.toUpperCase();"
                              id="doc-conjugue-socios"
                              v-model="socios.conjugueDocLegal"
                              :state="
                                socios.conjugueDocLegal !== null ? true : false
                              "
                            >
                            </b-form-input>
                          </b-col>
                        </div>
                      </b-form-row>
                    </b-form-group>
                  </div>
                </b-form-row>
                <!-- <div>
              <b-button class="btn btn-primary" @click="addSocio()"
                >Adicionar Proximo</b-button
              >
            </div>  -->

                <b-form-row
                  v-if="
                    validationTwo === true &&
                    this.identificacaoPf.socios !== '' &&
                    this.socios !== ''
                  "
                  class="row-form"
                >
                  <h3>Adicionados:</h3>
                  <b-table
                    class="tableConfirm"
                    cols="12"
                    style="width: 100%; margin-top: 3%"
                    responsive
                    dark
                    striped
                    small
                    :items="identificacaoPf.socios"
                    :fields="fieldsPf"
                  >
                    <template #cell(acao)="data">
                      <b-button
                        class="btn btn-danger fa fa-times"
                        href="#"
                        @click="removeEntry(data)"
                      ></b-button>
                    </template>
                  </b-table>
                </b-form-row>
                <hr />
                <div class="btnAdicionarsocio">
                  <b-button @click="voltar()" class="btnVoltar"
                    >Voltar</b-button
                  >
                  <b-button @click="addSocio()" class="btnIncluir"
                    >Incluir</b-button
                  >
                  <b-button
                    @click.prevent="salvaCadastro('pf')"
                    class="btnAvancar"
                    >Salvar</b-button
                  >
                </div>
              </b-form-group>
            </div>
          </transition>
        </b-form>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="9" offset="3" class="p-0 m-0 sticky">
        <div class="footer-interno">
          <p class="footer-text-black">
            COPYRIGHT
            <b style="color: darkorange; text-decoration: underline"
              >CMR INDÚSTRIA E COMÉRCIO.</b
            >
            2023-2024
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import { cpf, cnpj } from "cpf-cnpj-validator";
//import axios from "axios";
// import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
import { achaCep } from "../mixins/achaCep";
import { salvarCadastro } from "../mixins/salvarCadastro";

//import { map } from "underscore";

export default {
  namespaced: true,
  name: "CadastroPf",
  mixins: [achaCep, salvarCadastro],
  // components: { Footer },
  computed: {
    ...mapState(["validacaoAuth", "cadastroPf", "showMsgSuccess"]),
  },
  data() {
    return {
      showSuccess: true,
      validate: true,
      copiarSocios: false,
      sociosCount: 0,
      selecao: "1",
      fieldsPf: [
        { key: "nome", label: "Nome" },
        { key: "cpf", label: "CPF" },
        { key: "rg", label: "RG" },
        { key: "telefone", label: "Tel.:" },
        { key: "acao", label: "Ação" },
      ],
      estadoCivilSelected: "1",
      naciolidadeSelected: "1",
      socios: {
        nome: "",
        cpf: "",
        rg: "",
        orgaoExpeditor: "",
        profissao: "",
        nacionalidade: "",
        estadoCivil: "",
        estadoCivilSelected: "1",
        regimeBens: "",
        cep: "",
        telefone: "",
        endereco: "",
        num: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        emailsocio: "",
        paisOrigem: "",
        docLegal: "",
        copiar: "",
        nomeConjugue: "",
        cpfConjugue: "",
        rgConjugue: "",
        conjugueorgaoExpeditor: "",
        profissaoConjugue: "",
        nacionalidadeConjugue: "1",
        TelConjugue: "",
        conjuguePaisOrigem: "",
        conjugueDocLegal: "",
        emailConjugue: "",
      },
      sociosAcc: 0,
      validCep: "",
      validationOne: false,
      validationTwo: false,
      identificacaoPf: {
        doc: "cpf",
        cidadeLocalFranquia: "",
        email: "",
        nome: "",
        cpf: "",
        rg: "",
        orgaoExpeditor: "",
        profissao: "",
        nacionalidade: "1",
        estadoCivil: "1",
        regimeBens: "",
        telefone: "",
        cep: "",
        endereco: "",
        num: "",
        bairro: "",
        cidade: "",
        complemento: "",
        uf: "",
        docLegal: "",
        tipoDados: "S",
        paisOrigem: "",
        nomeConjugue: "",
        cpfConjugue: "",
        rgConjugue: "",
        conjugueOrgExp: "",
        profissaoConjugue: "",
        TelConjugue: "",
        nacionalidadeConjugue: "1",
        conjuguePaisOrigem: "",
        conjugueDocLegal: "",
        emailConjugue: "",
        socios: [],
      },
    };
  },

  //components: {cpf, cnpj, axios},
  methods: {
    test(caller, arg) {
      console.log(caller, arg);
    },
    /* eslint-disable */
    proximo() {
      if (
        this.identificacaoPf.nome === "" ||
        this.identificacaoPf.email === "" ||
        this.identificacaoPf.telefone === "" ||
        this.identificacaoPf.profissao === ""
      ) {
        this.$toasted
          .show("Preencher Campos Obrigatorios.", {
            theme: "bubble",
            position: "top-center",
          })
          .goAway(2000);
      } else {
        this.validationOne = true;
      }
    },
    voltar() {
      this.validationOne = false;
    },

    removeEntry(data) {
      console.log("removeEntry", data);
      this.identificacaoPf.socios.splice(data.index, 1);
      this.sociosAcc--;
    },

    copySocios() {
      this.copiarSocios = !this.copiarSocios;
      this.socios.tipoDados = "S";
      this.identificacaoPf.cpf = this.validacaoAuth;
      let newObj = Object.assign({}, this.identificacaoPf);
      delete newObj.socios;
      console.log(newObj, "newobj");
      if (this.copiarSocios === true) {
        this.socios.tipoDados = "S";
        this.validationTwo = true;
        this.identificacaoPf.socios.push(JSON.parse(JSON.stringify(newObj)));
        console.log("copiar dado", this.identificacaoPf);
      } else if (this.copiarSocios === false) {
        this.$nextTick(function () {
          this.removeEntry(this.socios);
        });
      }
    },
    addSocio() {
      this.socios.tipoDados = "S";
      this.identificacaoPf.cpf = this.validacaoAuth;

      if (
        this.sociosAcc < 3 &&
        this.socios.nome !== "" &&
        this.socios.cpf !== ""
      ) {
        this.validationTwo = true;
        this.identificacaoPf.socios.push(
          JSON.parse(JSON.stringify(this.socios))
        );

        this.socios.nome = "";
        this.socios.cpf = "";
        this.socios.rg = "";
        this.socios.cep = "";
        this.socios.profissao = "";
        this.socios.estadoCivil = "";
        this.socios.comunhaoBens = "";
        this.socios.telefone = "";
        this.socios.numero = "";
        this.socios.orgaoExpeditor = "";
        this.socios.nacionalidade = "1";
        this.socios.paisOrigem = "";
        this.socios.docLegal = "";
        this.socios.endereco = "";
        this.socios.bairro = "";
        this.socios.num = "";
        this.socios.cidade = "";
        this.socios.complemento = "";
        this.socios.uf = "";
        this.socios.nomeConjugue = "";
        this.socios.cpfConjugue = "";
        this.socios.rgConjugue = "";
        this.socios.conjugueOrgaoExpeditor = "";
        this.socios.nacionalidadeConjugue = "1";
        this.socios.conjugueRegimeBens = "";
        this.socios.profissaoConjugue = "";
        this.socios.conjugueDocLegal = "";
        this.socios.conjuguePaisOrigem = "";
        this.socios.email = "";
        this.socios.emailConjugue = "";

        this.sociosAcc++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#testecss {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

#estadocivil {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.btnAdicionarsocio {
  display: flex;
  justify-content: space-between;
}

#contentPf {
  background: url("./../../src/assets/bg-pf.png"), no-repeat, center, center,
    center !important;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
}
.footer-text {
  color: #604e48;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s !important;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0 !important;
  display: none;
}

.tableConfirm .socios {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}
.copiarDados {
  font-family: "Effra_std";
  font-size: 20px;
  padding: 10px;
  display: flex;
}
.form-controle {
  margin: 8% 8% 5% 8%;
}
.asideVoltar {
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #847675;
}
</style>
