<template>
  <div>
    <footer id="footer">
      <span class="footer-text">
        COPYRIGHT
        <b style="color: darkorange; text-decoration: underline"
          >CMR INDÚSTRIA E COMÉRCIO</b
        >
        2023-2024. - v0.0.3
      </span>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#footer {
  background: #604e48;
  color: white;
  height: 36px;
  padding-top: 5px;
}
.footer-text {
  color: white !important;
  text-align: center;
  justify-content: center;
}
</style>
