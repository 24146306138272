var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('Header'),_c('b-container',{ref:"containerContent",staticClass:"content",class:_vm.$store.state.showCadastroPf !== false
        ? 'contentPf'
        : _vm.$store.state.showCadastroPj !== false
        ? 'contentPj'
        : _vm.$store.state.showMsgSuccess !== false
        ? 'contentSuccess'
        : '',attrs:{"fluid":"","id":"content"}},[_c('b-row',{staticClass:"main-row"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-modal',{staticClass:"box-login-select float-right",attrs:{"title":"Cadastro  - Novos Franqueados","id":"box-login-select","no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","hide-backdrop":"","content-class":"shadow"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticStyle:{"width":"100%","margin":"0px 25%","padding":"5px"},attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.validarCadastro()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validarCadastro()}}},[_c('span',{staticStyle:{"font-weight":"lighter"}},[_vm._v("Entrar")])])]},proxy:true}])},[_c('div',{staticClass:"modalInput",staticStyle:{"display":"flex","flex-direction":"column","width":"100%"}},[_c('b-form-input',{staticClass:"m-0",attrs:{"placeholder":"Digite CPF para PF e CNPJ para PJ.","maxlength":"14","id":"codCadastro"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validarCadastro()}},model:{value:(_vm.validationAuth),callback:function ($$v) {_vm.validationAuth=$$v},expression:"validationAuth"}}),_c('b-form-input',{staticClass:"m-0",attrs:{"placeholder":"Digite o token de cadastro","maxlength":"14","id":"codCadastro"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validarCadastro()}},model:{value:(_vm.tokenAuth),callback:function ($$v) {_vm.tokenAuth=$$v},expression:"tokenAuth"}})],1)])],1)],1),_c('b-row',[(
          _vm.$store.state.showCadastroPj === true &&
          _vm.$store.state.showCadastroPf !== true
        )?_c('CadastroPj'):_vm._e(),(
          _vm.$store.state.showCadastroPf === true &&
          _vm.$store.state.showCadastroPj !== true
        )?_c('CadastroPf'):_vm._e()],1),_c('Sucesso',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.$store.state.showMsgSuccess === true &&
        _vm.$store.state.showCadastroPj !== true &&
        _vm.$store.state.showCadastroPf !== true
      ),expression:"\n        $store.state.showMsgSuccess === true &&\n        $store.state.showCadastroPj !== true &&\n        $store.state.showCadastroPf !== true\n      "}]})],1),(
      _vm.$store.state.showCadastroPf === false &&
      _vm.$store.state.showCadastroPj === false
    )?_c('Footer',{staticClass:"footer-interno"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }