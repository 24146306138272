import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from './mixins/jwtInterceptor'
import store from '/src/store'
import Toasted from 'vue-toasted'
import 'font-awesome/css/font-awesome.css'
import router from './router'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Toasted)
Vue.use(IconsPlugin)
Vue.use(axios)

new Vue({
  store : store,
  axios,
  router,
  render: h => h(App),
}).$mount('#app')
