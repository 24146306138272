<template>
  <div>
    <b-modal
      style="border-radius: 25px; background-color: #f9f4f2"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-backdrop
      content-class="shadow"
    >
      <h2 style="color: #f58025">Cadastro Realizado com sucesso!</h2>
      <h3 style="color: #383838 !important">
        Suas informações foram enviadas para nosso time de cadastro.
      </h3>
      <p style="width: 100%; text-align: start; color: #4f4f4e !important">
        Agora deixe tudo conosco, avisaremos quando seu cadastro estiver ativo.
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Successo",
  data() {
    return {};
  },
  methods: {},
};
</script>
