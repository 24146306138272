import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'

Vue.use(VueRouter)

const routes = [
    {
        name: 'Home',
        path: '/',
        component: App,
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
})

export default router