import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		headerMsg: '',
		showReferencias: false,
		showGarantidores: false,
		validacaoAuth: Number,
		tokenAuth: Number,
		authMsg: '',
		showCadastroPf: false,
		showCadastroPj: false,
		showMsgSuccess: false,
		cadastroPf: {},
		cadastroPj: {},
		selectRegimeBens: [
			{name: 'SEPARAÇÃO', item: "1"},
			{name: 'PARCIAL', item: "2"},
			{name: 'UNIVERSAL', item: "3"}
		],
		selectRegimeTributario: [
			{name: "SIMPLES NACIONAL", item: "1"},
			{name: "LUCRO PRESUMIDO", item: "2"},
			{name: "LUCRO REAL", item: "3"}
		],
		selectEstadoCivil: [
			{ name: "SOLTEIRO", item: "1" },
			{ name: "CASADO", item: "2" },
			{ name: "VIÚVO", item: "3" },
			{ name: "SEPARADO", item: "4" },
			{ name: "UNIÃO ESTÁVEL", item: "5" }
		],
		nacionalidadeSelect: [
			{name:'BRASILEIRA', item:"1"},
			{name:'ESTRANGEIRA', item:"2"},
		],
		ufs: [
			"AL",
			"BA",
			"CE",
			"MA",
			"PB",
			"PE",
			"PI",
			"RN",
			"SE",
			"DF",
			"GO",
			"MS",
			"MT",
			"AC",
			"AM",
			"AP",
			"PA",
			"RO",
			"RR",
			"TO",
			"ES",
			"MG",
			"RJ",
			"SP",
			"PR",
			"RS",
			"SC",
		],
		
	},
	mutations: {
		setCadastroPf(state, cadastroPf) {
			state.cadastroPf = cadastroPf;
		},
		setCadastroPj(state, cadastroPj) {
			state.cadastroPj = cadastroPj;
		},
		setValidacaoAuth(state, validacaoAuth) {
			state.validacaoAuth = validacaoAuth;
		},
		setShowMsgSuccess(state, showMsgSuccess) {
			state.showMsgSuccess = showMsgSuccess;
		},
		setAuthMsg(state, authMsg){
			state.authMsg = authMsg;
		}


	}

})