// Interceptor para qualquer chamada feita através do axios para validar o tokenjwtToken
import axios from "axios";

   axios.interceptors.request.use((config) => {
    console.log('cfg2', config)
     const jwtToken = JSON.parse(localStorage.getItem('token'))
    console.log('cfg2', jwtToken)

     if (jwtToken == null) {
       return config;
     }
     config.headers.common["Authorization"] = `bearer ${jwtToken.token}`;
     return config;
   });

    axios.interceptors.response.use(
      (response) => {
        
        return response;
      },
      async (error) => {
       if (error.response.status === 401) {    

         console.log(error.response, 'jwtinterceptor')
       } else {
         //this.$router.push({ path:"/login" })
         alert("entrou aqui teste");
         return Promise.reject(error);
       }
      }
    );

  export default axios