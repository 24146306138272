<template>
  <div>
    <b-row style="min-height: 100vh">
      <b-col cols="1" md="3" style="background: #897167">
        <div class="asideVoltar">
          <b-button v-if="showIdentificacao === true" variant="outline"
            ><i class="fa fa-5x fa-arrow-left" style="color: white"></i>
            <p style="color: white">Voltar</p>
          </b-button>
          <b-button
            v-else-if="showReferencias === true"
            @click="voltar('refs')"
            variant="outline"
          >
            <i class="fa fa-5x fa-arrow-left" style="color: white"></i>
            <p style="color: white">Voltar</p>
          </b-button>
          <b-button
            v-else-if="showGarantidores === true"
            @click="voltar('garan')"
            variant="outline"
          >
            <i class="fa fa-5x fa-arrow-left" style="color: white"></i>
            <p style="color: white">Voltar</p>
          </b-button>
        </div>
      </b-col>

      <b-col cols="11" md="9" style="background: #f9f4f2 !important">
        <transition name="fade">
          <div v-show="showIdentificacao === true" style="margin: 12% 8% 5% 8%">
            <h2 style="color: #604e48 !important">
              Cadastro Pessoa Jurídica -
            </h2>
            <h3>Sócios Administradores</h3>

            <h4 style="margin: 3% 0px; font-weight: bold; letter-spacing: 2px">
              Identificação
            </h4>

            <b-form-group>
              <b-form-row class="row-form">
                <b-col cols="6">
                  <label class="form-label-custom" for="razao-social"
                    >Nome/Razão Social</label
                  >
                  <b-form-input
                    id="razao-social"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    maxlength="30"
                    v-model="identificacaoPj.razaoSocial"
                    :state="
                      identificacaoPj.razaoSocial !== '' ||
                      identificacaoPj.razaoSocial.length > 5
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="6">
                  <label class="form-label-custom" for="inscricao-estadual"
                    >Inscrição Estadual</label
                  >
                  <b-form-input
                    maxlength="15"
                    id="inscricao-estadual"
                    type="number"
                    v-model="identificacaoPj.inscricaoEstadual"
                    :state="
                      identificacaoPj.inscricaoEstadual !== '' ||
                      identificacaoPj.inscricaoEstadual.length > 5
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="CNPJ">CNPJ</label>
                  <b-form-input
                    type="number"
                    id="CNPJ"
                    :disabled="true"
                    maxlenght="16"
                    v-model="this.$store.state.validacaoAuth"
                    :state="
                      this.$store.state.validacaoAuth !== '' ||
                      this.$store.state.validacaoAuth.length > 5
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="5">
                  <label class="form-label-custom" for="cep-sede"
                    >CEP Sede</label
                  >
                  <b-form-input
                    type="number"
                    maxlength="10"
                    id="cep-sede"
                    v-model="identificacaoPj.cep"
                    :state="
                      identificacaoPj.cep !== '' ||
                      identificacaoPj.cep.length > 4
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="1">
                  <b-button
                    variant="outline-success"
                    @click="achaEnd(identificacaoPj.cep, 'id')"
                    style="margin-top: 40px"
                    ><i class="fa fa-search"></i
                  ></b-button>
                </b-col>
                <b-col cols="6">
                  <label class="form-label-custom" for="endereco-sede"
                    >Endereço Sede</label
                  >
                  <b-form-input
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="endereco-sede"
                    maxlength="50"
                    v-model="identificacaoPj.endereco"
                    :state="
                      identificacaoPj.endereco !== '' ||
                      identificacaoPj.endereco.length > 5
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="3">
                  <label class="form-label-custom" for="cidade-sede"
                    >Cidade Sede</label
                  >
                  <b-form-input
                    maxlength="35"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="cidade-sede"
                    v-model="identificacaoPj.cidade"
                    :state="
                      identificacaoPj.cidade !== '' ||
                      identificacaoPj.cidade.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="3">
                  <label class="form-label-custom" for="bairro-sede"
                    >Bairro Sede</label
                  >
                  <b-form-input
                    maxlength="35"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="bairro-sede"
                    v-model="identificacaoPj.bairro"
                    :state="
                      identificacaoPj.bairro !== '' ||
                      identificacaoPj.bairro.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="3">
                  <label class="form-label-custom" for="Num">Num</label>
                  <b-form-input
                    maxlength="7"
                    type="number"
                    id="Num"
                    v-model="identificacaoPj.numero"
                    :state="
                      identificacaoPj.numero !== '' ||
                      identificacaoPj.numero.length > 0
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="3">
                  <label class="form-label-custom" for="if-sede">UF Sede</label>
                  <b-form-input
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="uf-sede"
                    maxlength="3"
                    v-model="identificacaoPj.uf"
                    :state="
                      identificacaoPj.uf !== '' || identificacaoPj.uf.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="6">
                  <label class="form-label-custom" for="email">E-mail</label>
                  <b-form-input
                    maxlength="30"
                    id="email"
                    type="email"
                    oninput="this.value = this.value.toUpperCase()"
                    v-model="identificacaoPj.email"
                    :state="
                      identificacaoPj.email !== '' ||
                      identificacaoPj.email.length > 4
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="4">
                  <label class="form-label-custom" for="fone">DDD/Fone</label>
                  <b-form-input
                    type="tel"
                    maxlenght="15"
                    oninput=" this.value = this.value.replace(/[^0-9]/g, '')"
                    id="fone"
                    v-model="identificacaoPj.telefone"
                    :state="
                      identificacaoPj.telefone !== '' ||
                      identificacaoPj.telefone.length > 6
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="4">
                  <label class="form-label-custom" for="fax">DDD/Fax</label>
                  <b-form-input
                    type="tel"
                    maxlenght="15"
                    oninput=" this.value = this.value.replace(/[^0-9]/g, '')"
                    id="fax"
                    v-model="identificacaoPj.fax"
                  >
                  </b-form-input>
                </b-col>
                <!-- <b-col cols="4">
							<label class="form-label-custom" for=""></label>
              <b-form-input oninput="this.value = this.value.toUpperCase()" type="text"
								placeholder="Regime tributário" v-model="identificacaoPj.regimeTributario"
                id="Regime tributário" v-model="identificacaoPj.regimeTributario"
								:state="identificacaoPj.regimeTributario !== '' || identificacaoPj.regimeTributario.length > 1">
							</b-form-input>
						</b-col> -->

                <b-col cols="4">
                  <label class="form-label-custom" for="regime-tributario"
                    >Regime Tributário</label
                  >
                  <b-form-select
                    value-field="name"
                    text-field="name"
                    class="form-control"
                    :options="$store.state.selectRegimeTributario"
                    id="regime-tributario"
                    v-model="identificacaoPj.regimeTributario"
                  >
                    <template #first>
                      <option disabled selected>
                        -- Selecione uma opção. --
                      </option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-form-row>

              <b-button type="submit" @click="loadAdm()" class="btnAvancar"
                >Avançar</b-button
              >
            </b-form-group>
          </div>
        </transition>

        <transition name="fade">
          <div v-show="showReferencias === true" style="margin: 8% 8% 5% 8%">
            <div class="txtBox">
              <h2>Cadastro Pessoa Jurídica</h2>

              <h3 style="letter-spacing: 1.2px; font-weight: 500">
                Sócios Administradores
              </h3>
            </div>

            <div class="teste">
              <h4
                style="margin: 3% 0px; font-weight: bold; letter-spacing: 2px"
              >
                Referências
              </h4>
            </div>
            <b-form-group>
              <b-form-row class="row-form">
                <b-col cols="6">
                  <label class="form-label-custom" for="nome-socios"
                    >Nome Sócio</label
                  >
                  <b-form-input
                    maxlenght="30"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="nome-socios"
                    v-model="socios.nome"
                    :state="socios.nome !== '' || socios.nome.length > 1"
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="6">
                  <label class="form-label-custom" for="telefone-socios"
                    >Tel. Sócio</label
                  >
                  <b-form-input
                    type="tel"
                    maxlenght="15"
                    oninput=" this.value = this.value.replace(/[^0-9]/g, '')"
                    id="telefone-socios"
                    v-model="socios.telefone"
                    :state="
                      socios.telefone !== '' || socios.telefone.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>
              </b-form-row>

              <b-form-row class="row-form">
                <b-col cols="6">
                  <label class="form-label-custom" for="cpf-socios"
                    >CPF Sócio</label
                  >
                  <b-form-input
                    type="number"
                    maxlenght="11"
                    id="cpf-socios"
                    v-model="socios.cpf"
                    :state="socios.cpf !== '' || socios.cpf.length > 1"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="nacionalidade-socios"
                    >Nacionalidade Sócio</label
                  >
                  <b-form-select
                    id="nacionalidade-socios"
                    value-field="name"
                    text-field="name"
                    class="form-control"
                    :options="$store.state.nacionalidadeSelect"
                    v-model="socios.nacionalidade"
                    :state="socios.nacionalidade.length > 2"
                  >
                    <template #first>
                      <b-form-select-option v-bind:value="1" disabled
                        >-- Selecione uma opção. --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-col>
                <div
                  v-show="socios.nacionalidade === 'BRASILEIRA'"
                  style="width: 100%; display: flex; flex-wrap: wrap"
                >
                  <b-col cols="6">
                    <label class="form-label-custom" for="rg-socios"
                      >RG Sócio</label
                    >
                    <b-form-input
                      type="number"
                      maxlength="15"
                      id="rg-socios"
                      v-model="socios.rg"
                      :state="socios.rg !== '' || socios.rg.length > 1"
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label
                      class="form-label-custom"
                      for="orgao-expeditor-socios"
                      >Orgão Expeditor</label
                    >
                    <b-form-input
                      maxlenght="8"
                      oninput="this.value = this.value.toUpperCase()"
                      type="text"
                      id="orgao-expeditor-socios"
                      v-model="socios.orgaoExpeditor"
                      :state="
                        socios.orgaoExpeditor !== '' ||
                        socios.orgaoExpeditor.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>
                </div>

                <div
                  v-show="socios.nacionalidade === 'ESTRANGEIRA'"
                  style="width: 100%; display: flex; flex-wrap: wrap"
                >
                  <b-col cols="6">
                    <label class="form-label-custom" for="pais-socios"
                      >País Origem</label
                    >
                    <b-form-input
                      v-model="socios.paisOrigem"
                      id="pais-socios"
                      oninput="this.value = this.value.toUpperCase()"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label class="form-label-custom" for="doc-legal-socios"
                      >Documento Legal Válido</label
                    >
                    <b-form-input
                      v-model="socios.docLegal"
                      id="doc-legal-socios"
                    ></b-form-input>
                  </b-col>
                </div>

                <b-col cols="5">
                  <label class="form-label-custom" for="cep-socios"
                    >CEP Sócio</label
                  >
                  <b-form-input
                    maxlenght="10"
                    type="number"
                    id="cep-socios"
                    v-model="socios.cep"
                    :state="socios.cep !== '' || socios.cep.length > 1"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="1">
                  <b-button
                    variant="outline-success"
                    style="margin-top: 40px"
                    :label="'Pesquisar'"
                    @click="achaEnd(socios.cep, 'refs')"
                    ><i class="fa fa-search"></i
                  ></b-button>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="endereco-socios"
                    >Endereço Completo</label
                  >
                  <b-form-input
                    maxlenght="50"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="endereco-socios"
                    v-model="socios.endereco"
                    :state="
                      socios.endereco !== '' || socios.endereco.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="3">
                  <label class="form-label-custom" for="numero-socios"
                    >Num</label
                  >
                  <b-form-input
                    maxlenght="8"
                    type="number"
                    id="numero-socios"
                    v-model="socios.num"
                    :state="socios.num !== '' || socios.num.length > 1"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="3">
                  <label class="form-label-custom" for="complmento-socios"
                    >Complemento</label
                  >
                  <b-form-input
                    maxlenght="30"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="complemento-socios"
                    v-model="socios.complemento"
                    :state="
                      socios.complemento !== '' || socios.complemento.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="bairro-socios"
                    >Bairro</label
                  >
                  <b-form-input
                    maxlenght="30"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="bairro-socios"
                    v-model="socios.bairro"
                    :state="socios.bairro !== '' || socios.bairro.length > 1"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="8">
                  <label class="form-label-custom" for="cidade-socios"
                    >Cidade</label
                  >
                  <b-form-input
                    maxlenght="30"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="cidade-socios"
                    v-model="socios.cidade"
                    :state="socios.cidade !== '' || socios.cidade.length > 1"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="4">
                  <label class="form-label-custom" for="uf-socios">UF</label>
                  <b-form-input
                    maxlenght="3"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="uf-socios"
                    v-model="socios.uf"
                    :state="socios.uf !== '' || socios.uf.length > 1"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="profissao-socios"
                    >Profissão</label
                  >
                  <b-form-input
                    v-model="socios.profissao"
                    :state="socios.profissao.length > 3 ? true : false"
                    oninput="this.value = this.value.toUpperCase()"
                    id="profissao-socios"
                    type="text"
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="estado-civil-socios"
                    >Estado Civil</label
                  >
                  <b-form-select
                    value-field="name"
                    text-field="name"
                    class="form-control"
                    size="sm"
                    id="estado-civil-socios"
                    :options="$store.state.selectEstadoCivil"
                    v-model="socios.estadoCivil"
                  >
                    <!-- <template #first>
                  <b-form-select-option v-bind:value="1" disabled
                    >-- Selecione uma opção. --</b-form-select-option
                  >
              </template>  -->
                    <option value="" disabled>
                      -- Selecione uma opção. --
                    </option>
                  </b-form-select>
                </b-col>
              </b-form-row>

              <b-form-group>
                <div
                  border-variant="info"
                  v-show="
                    (socios.estadoCivil === 'CASADO' ||
                      socios.estadoCivil === 'UNIÃO ESTÁVEL') &&
                    showReferencias === true
                  "
                >
                  <h3>Dados Cônjugue.</h3>
                  <b-form-row class="row-form">
                    <b-col cols="4">
                      <label
                        class="form-label-custom"
                        for="nome-conjugue-socios"
                        >Nome Conjugue</label
                      >
                      <b-form-input
                        oninput="this.value = this.value.toUpperCase()"
                        type="text"
                        id="nome-conjugue-socios"
                        v-model="socios.nomeConjugue"
                      >
                      </b-form-input>
                    </b-col>

                    <b-col>
                      <label class="form-label-custom" for="regime-bens-socios"
                        >Regime Bens</label
                      >
                      <b-form-select
                        id="regime-bens-socios"
                        value-field="name"
                        text-field="name"
                        class="form-control"
                        :options="$store.state.selectRegimeBens"
                        v-model="socios.regimeBens"
                      >
                        <option value="" disabled>
                          -- Selecione uma opção. --
                        </option>
                      </b-form-select>

                      <!-- <b-form-input oninput="this.value = this.value.toUpperCase()" type="text"
											
                      
										</b-form-input> -->
                    </b-col>

                    <b-col cols="4">
                      <label class="form-label-custom" for="cpf-conjugue-socios"
                        >CPF Conjugue</label
                      >
                      <b-form-input
                        maxlenght="11"
                        type="number"
                        id="cpf-conjugue-socios"
                        v-model="socios.cpfConjugue"
                        :state="
                          socios.cpfConjugue !== '' ||
                          socios.cpfConjugue.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>

                    <b-col cols="4">
                      <label class="form-label-custom" for="rg-conjugue-socios"
                        >RG Conjugue</label
                      >
                      <b-form-input
                        maxlenght="15"
                        type="number"
                        id="rg-conjugue-socios"
                        v-model="socios.rgConjugue"
                        :state="
                          socios.rgConjugue !== '' ||
                          socios.rgConjugue.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>

                    <b-col cols="4">
                      <label
                        class="form-label-custom"
                        for="profissao-conjugue-socios"
                        >Profissão Conjugue</label
                      >
                      <b-form-input
                        maxlenght="30"
                        oninput="this.value = this.value.toUpperCase()"
                        type="text"
                        id="profissao-conjugue-socios"
                        v-model="socios.profissaoConjugue"
                        :state="
                          socios.profissaoConjugue !== '' ||
                          socios.profissaoConjugue.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>

                    <b-col cols="4">
                      <label
                        class="form-label-custom"
                        for="nacionalidade-conjugue-socios"
                        >Nacionalidade Conjugue</label
                      >
                      <b-form-select
                        id="nacionalidade-conjugue-socios"
                        value-field="name"
                        text-field="name"
                        class="form-control"
                        oninput="this.value = this.value.toUpperCase()"
                        :options="$store.state.nacionalidadeSelect"
                        type="text"
                        v-model="socios.nacionalidadeConjugue"
                        :state="
                          socios.nacionalidadeConjugue !== '' ||
                          socios.nacionalidadeConjugue.length > 1
                        "
                      >
                        <b-form-select-option v-bind:value="1" disabled
                          >-- Selecione uma opção. --</b-form-select-option
                        >
                      </b-form-select>
                    </b-col>
                    <b-col cols="12">
                      <label
                        class="form-label-custom"
                        for="email-conjugue-socios"
                        >E-mail Conjugue</label
                      >
                      <b-form-input
                        type="email"
                        oninput="this.value = this.value.toUpperCase()"
                        id="email-conjugue-socios"
                        v-model="socios.emailConjugue"
                        :state="socios.emailConjugue !== '' ? true : false"
                      ></b-form-input>
                    </b-col>
                    <div
                      v-show="socios.nacionalidadeConjugue === 'ESTRANGEIRA'"
                      style="width: 100%; display: flex; flex-wrap: wrap"
                    >
                      <b-col cols="6">
                        <label
                          class="form-label-custom"
                          for="pais-conjugue-socios"
                          >País Origem Conjugue</label
                        >
                        <b-form-input
                          id="pais-conjugue-socios"
                          v-model="socios.conjuguePaisOrigem"
                          :state="
                            socios.conjuguePaisOrigem !== null ? true : false
                          "
                        >
                        </b-form-input>
                      </b-col>
                      <b-col cols="6">
                        <label
                          class="form-label-custom"
                          for="doc-conjugue-socios"
                          >Nº Documento Legal</label
                        >
                        <b-form-input
                          id="doc-conjugue-socios"
                          v-model="socios.conjugueDocLegal"
                          :state="
                            socios.conjugueDocLegal !== null ? true : false
                          "
                        >
                        </b-form-input>
                      </b-col>
                    </div>
                  </b-form-row>
                </div>
              </b-form-group>

              <!-- <b-col cols="4">
						<label class="form-label-custom" for=""></label>
            
              
					</b-col> -->

              <b-form-row>
                <div class="col-12">
                  <div
                    class="btn-area"
                    style="
                      display: flex;
                      justify-content: space-around;
                      margin-bottom: 16px;
                    "
                  >
                    <b-button @click="voltar('refs')" class="btnVoltar"
                      >Voltar</b-button
                    >

                    <b-button
                      type="submit"
                      class="btnIncluir"
                      @click="addSocios()"
                      >Incluir</b-button
                    >

                    <b-button
                      type="submit"
                      class="btnAvancar"
                      @click="saveSocios()"
                      >Avançar</b-button
                    >
                  </div>
                </div>
              </b-form-row>
            </b-form-group>

            <b-table
              v-show="identificacaoPj.socios.length > 0 ? true : false"
              style="
                border: 1px solid black;
                margin-top: 20px;
                width: 100%;
                font-size: 17px;
              "
              :items="identificacaoPj.socios"
              dark
              :fields="fieldsSocios"
              responsive
              light
              striped
              small
              hover
            >
              <template v-slot:cell(Action)="data">
                <b-button
                  class="btn btn-danger fa fa-times"
                  href="#"
                  @click="removeEntry(data, 'referencia')"
                ></b-button>
              </template>
            </b-table>
          </div>
        </transition>

        <transition name="fade">
          <div v-show="showGarantidores === true" style="margin: 12% 8% 5% 8%">
            <h2>Cadastro Pessoa Jurídica</h2>
            <h4>Garantidores</h4>

            <b-form-group>
              <b-form-row class="row-form">
                <b-col cols="6">
                  <label class="form-label-custom" for="nome-garantidores"
                    >Nome Garantidor</label
                  >
                  <b-form-input
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="nome-garantidores"
                    v-model="garantidores.nome"
                    maxlenght="30"
                    :state="
                      garantidores.nome !== '' || garantidores.nome.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="cpf-garantidores"
                    >CPF Garantidor</label
                  >
                  <b-form-input
                    type="number"
                    maxlenght="11"
                    id="cpf-garantidores"
                    v-model="garantidores.cpf"
                    :state="
                      garantidores.cpf !== '' || garantidores.cpf.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="tel-garantidores"
                    >Telefone</label
                  >
                  <b-form-input
                    type="tel"
                    maxlenght="15"
                    oninput=" this.value = this.value.replace(/[^0-9]/g, '')"
                    id="tel-garantidores"
                    v-model="garantidores.telefone"
                    :state="
                      garantidores.telefone !== '' ||
                      garantidores.telefone.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="6">
                  <label class="form-label-custom" for="profissao-garantidores"
                    >Profissão</label
                  >
                  <b-form-input
                    maxlenght="30"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="profissao-garantidores"
                    v-model="garantidores.profissao"
                    :state="
                      garantidores.profissao !== '' ||
                      garantidores.profissao.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="5">
                  <label class="form-label-custom" for="cep-garantidores"
                    >CEP</label
                  >
                  <b-form-input
                    type="number"
                    maxlenght="10"
                    id="cep-garantidores"
                    v-model="garantidores.cep"
                    :state="
                      garantidores.cep !== '' || garantidores.cep.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>
                <b-col cols="1">
                  <b-button
                    variant="outline-success"
                    style="margin-top: 40px"
                    @click="achaEnd(garantidores.cep, 'garan')"
                    ><i class="fa fa-search"></i
                  ></b-button>
                </b-col>
                <b-col cols="6">
                  <label
                    class="form-label-custom"
                    for="nacionalidade-garantidores"
                    >Nacionalidade</label
                  >
                  <b-form-select
                    value-field="name"
                    text-field="name"
                    class="form-control"
                    :options="$store.state.nacionalidadeSelect"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="nacionalidade-garantidores"
                    v-model="garantidores.nacionalidade"
                    :state="
                      garantidores.nacionalidade !== '' ||
                      garantidores.nacionalidade.length > 1
                    "
                  >
                    <template #first>
                      <b-form-select-option v-bind:value="1" disabled
                        >-- Selecione uma opção. --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-col>
                <div
                  v-show="garantidores.nacionalidade === 'BRASILEIRA'"
                  style="width: 100%; display: flex; flex-wrap: wrap"
                >
                  <b-col cols="6">
                    <label class="form-label-custom" for="rg-garantidores"
                      >RG Garantidor</label
                    >
                    <b-form-input
                      type="number"
                      maxlength="15"
                      id="rg-garantidores"
                      v-model="garantidores.rg"
                      :state="
                        garantidores.rg !== '' || garantidores.rg.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label
                      class="form-label-custom"
                      for="orgao-expeditor-garantidores"
                      >Orgão Expeditor</label
                    >
                    <b-form-input
                      maxlenght="8"
                      oninput="this.value = this.value.toUpperCase()"
                      type="text"
                      id="orgao-expedidor-garantidores"
                      v-model="garantidores.orgaoExpeditor"
                      :state="
                        garantidores.orgaoExpeditor !== '' ||
                        garantidores.orgaoExpeditor.length > 1
                      "
                    >
                    </b-form-input>
                  </b-col>
                </div>

                <div
                  v-show="garantidores.nacionalidade === 'ESTRANGEIRA'"
                  style="width: 100%; display: flex; flex-wrap: wrap"
                >
                  <b-col cols="6">
                    <label class="form-label-custom" for="pais-garantidores"
                      >País Origem</label
                    >
                    <b-form-input
                      v-model="garantidores.paisOrigem"
                      id="pais-garantidores"
                    ></b-form-input>
                  </b-col>

                  <b-col cols="6">
                    <label
                      class="form-label-custom"
                      for="doc-legal-garantidores"
                      >Documento Legal Válido</label
                    >
                    <b-form-input
                      v-model="garantidores.docLegal"
                      id="doc-legal-garantidores"
                    ></b-form-input>
                  </b-col>
                </div>

                <b-col cols="8">
                  <label class="form-label-custom" for="endereco-garantidores"
                    >Endereço Completo</label
                  >
                  <b-form-input
                    maxlenght="50"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="endereco-garantidores"
                    v-model="garantidores.endereco"
                    :state="
                      garantidores.endereco !== '' ||
                      garantidores.endereco.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="4">
                  <label class="form-label-custom" for="bairro-garantidores"
                    >Bairro</label
                  >
                  <b-form-input
                    maxlength="35"
                    type="text"
                    id="bairro-garantidores"
                    v-model="garantidores.bairro"
                    :state="
                      garantidores.bairro !== '' ||
                      garantidores.bairro.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="2">
                  <label class="form-label-custom" for="num-garantidores"
                    >Num</label
                  >
                  <b-form-input
                    maxlenght="8"
                    type="number"
                    id="num-garantidores"
                    v-model="garantidores.num"
                    :state="
                      garantidores.num !== '' || garantidores.num.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="3">
                  <label
                    class="form-label-custom"
                    for="complemento-garantidores"
                    >Complemento</label
                  >
                  <b-form-input
                    maxlength="30"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="complemento-garantidores"
                    v-model="garantidores.complemento"
                    :state="
                      garantidores.complemento !== '' ||
                      garantidores.complemento.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="5">
                  <label class="form-label-custom" for="cidade-garantidores"
                    >Cidade</label
                  >
                  <b-form-input
                    maxlength="35"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="cidade-garantidores"
                    v-model="garantidores.cidade"
                    :state="
                      garantidores.cidade !== '' ||
                      garantidores.cidade.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="2">
                  <label class="form-label-custom" for="uf-garantidores"
                    >UF</label
                  >
                  <b-form-input
                    maxlength="3"
                    oninput="this.value = this.value.toUpperCase()"
                    type="text"
                    id="uf-garantiores"
                    v-model="garantidores.uf"
                    :state="
                      garantidores.uf !== '' || garantidores.uf.length > 1
                    "
                  >
                  </b-form-input>
                </b-col>

                <b-col cols="12">
                  <label
                    class="form-label-custom"
                    for="estado-civil-garantidores"
                    >Estado Civil</label
                  >
                  <b-form-select
                    id="estado-civil-garantidores"
                    value-field="name"
                    text-field="name"
                    class="form-control"
                    size="sm"
                    :options="$store.state.selectEstadoCivil"
                    v-model="garantidores.estadoCivil"
                  >
                    <option value="" disabled style="color: lightgray">
                      -- Selecione uma opção. --
                    </option>
                  </b-form-select>
                </b-col>

                <b-form-group>
                  <div
                    style="width: 100%; display: flex; flex-wrap: wrap"
                    v-show="
                      (garantidores.estadoCivil === 'CASADO' ||
                        garantidores.estadoCivil === 'UNIÃO ESTÁVEL') &&
                      showGarantidores === true
                    "
                  >
                    <h3>Dados Conjugue Garantidores.</h3>
                    <b-col cols="12">
                      <label
                        class="form-label-custom"
                        for="nome-conjugue-garantidores"
                        >Nome Conjugue</label
                      >
                      <b-form-input
                        maxlength="30"
                        oninput="this.value = this.value.toUpperCase()"
                        type="text"
                        id="nome-conjugue-garantidores"
                        v-model="garantidores.nomeConjugue"
                        :state="
                          garantidores.nomeConjugue !== '' ||
                          garantidores.nomeConjugue.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label
                        class="form-label-custom"
                        for="regimebens-conjugue-garantidores"
                        >Regime Bens</label
                      >
                      <b-form-select
                        value-field="name"
                        text-field="name"
                        class="form-control"
                        :options="$store.state.selectRegimeBens"
                        id="regimebens-conjugue-garantidores"
                        v-model="garantidores.regimeBens"
                        :state="
                          garantidores.regimeBens !== '' ||
                          garantidores.regimeBens.length > 1
                        "
                      >
                        <b-form-select-option v-bind:value="1" disabled
                          >-- Selecione uma opção. --</b-form-select-option
                        >
                      </b-form-select>
                      <label class="form-label-custom" for=""></label>
                      <!-- <b-form-input maxlength="10" oninput="this.value = this.value.toUpperCase()" type="text"
				placeholder="Regime Comunhão Bens" v-model="garantidores.regimeBens"
        id="Regime Comunhão Bens" v-model="garantidores.regimeBens"
				:state="garantidores.regimeBens !== '' || garantidores.regimeBens.length > 1">
			</b-form-input> -->
                    </b-col>

                    <b-col cols="6">
                      <label
                        class="form-label-custom"
                        for="cpf-conjugue-garantidores"
                        >CPF Conjugue</label
                      >
                      <b-form-input
                        maxlength="7"
                        type="number"
                        id="cpf-conjuge-garantidores"
                        v-model="garantidores.cpfConjugue"
                        :state="
                          garantidores.cpfConjugue !== '' ||
                          garantidores.cpfConjugue.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>

                    <b-col cols="12">
                      <label
                        class="form-label-custom"
                        for="email-conjugue-garantidores"
                        >E-mail Conjugue</label
                      >
                      <b-form-input
                        type="email"
                        oninput="this.value = this.value.toUpperCase()"
                        id="email-conjugue-garantidores"
                        v-model="garantidores.emailConjugue"
                        :state="
                          garantidores.emailConjugue !== '' ? true : false
                        "
                      ></b-form-input>
                    </b-col>

                    <b-col cols="4">
                      <label
                        class="form-label-custom"
                        for="rg-conjugue-garantidores"
                        >RG Conjugue</label
                      >
                      <b-form-input
                        maxlength="15"
                        type="number"
                        id="rg-conjugue-garantidores"
                        v-model="garantidores.rgConjugue"
                        :state="
                          garantidores.rgConjugue !== '' ||
                          garantidores.rgConjugue.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>

                    <b-col cols="4">
                      <label
                        class="form-label-custom"
                        for="profissao-conjugue-garantidores"
                        >Profissão Conjugue</label
                      >
                      <b-form-input
                        maxlength="30"
                        oninput="this.value = this.value.toUpperCase()"
                        type="text"
                        id="profissao-conjugue-garantidores"
                        v-model="garantidores.conjugueProfissao"
                        :state="
                          garantidores.conjugueProfissao !== '' ||
                          garantidores.conjugueProfissao.length > 1
                        "
                      >
                      </b-form-input>
                    </b-col>

                    <b-col cols="4">
                      <label
                        class="form-label-custom"
                        for="nacionaliade-conjugue-garantidores"
                        >Nacionalidade Conjugue</label
                      >
                      <b-form-select
                        value-field="name"
                        text-field="name"
                        class="form-control"
                        :options="$store.state.nacionalidadeSelect"
                        oninput="this.value = this.value.toUpperCase()"
                        type="text"
                        id="nacionalidade-conjugue-garantidores"
                        v-model="garantidores.nacionalidadeConjugue"
                        :state="
                          garantidores.nacionalidadeConjugue !== '' ||
                          garantidores.nacionalidadeConjugue.length > 1
                        "
                      >
                        <b-form-select-option v-bind:value="1" disabled
                          >-- Selecione uma opção. --</b-form-select-option
                        >
                      </b-form-select>
                    </b-col>

                    <div
                      v-show="
                        garantidores.nacionalidadeConjugue === 'ESTRANGEIRA'
                      "
                      style="width: 100%; display: flex; flex-wrap: wrap"
                    >
                      <b-col cols="6">
                        <label
                          class="form-label-custom"
                          for="pais-conjugue-garantidores"
                          >País Origem</label
                        >
                        <b-form-input
                          id="pais-conjugue-garantidores"
                          v-model="garantidores.conjuguePaisOrigem"
                          class="form-control"
                          :state="garantidores.conjuguePaisOrigem !== null"
                        >
                        </b-form-input>
                      </b-col>
                      <b-col cols="6">
                        <label
                          class="form-label-custom"
                          for="doc-conjugue-garantidores"
                          >Documento Legal Válido</label
                        >
                        <b-form-input
                          id="doc-conjugue-garantidores"
                          v-model="garantidores.conjugueDocLegal"
                          class="form-control"
                          :state="garantidores.conjugueDocLegal !== null"
                        >
                        </b-form-input>
                      </b-col>
                    </div>
                  </div>
                </b-form-group>
                <b-col cols="6" style="padding: 20px; margin-left: -20px">
                </b-col>
                <b-col cols="12">
                  <div class="button-area d-flex justify-content-between">
                    <b-button class="btnVoltar" @click="voltar('garan')"
                      >Voltar</b-button
                    >
                    <b-button class="btnIncluir" @click="saveGarantidores()"
                      >Incluir</b-button
                    >
                    <b-button
                      class="btn"
                      @click.prevent="salvaCadastro('pj', el)"
                      >Salvar</b-button
                    >
                  </div>
                </b-col>
              </b-form-row>
            </b-form-group>
            <b-row>
              <b-col cols="12">
                <b-table
                  style="border: 1px solid black; width: 100%"
                  v-show="identificacaoPj.socios.length > 0"
                  :items="identificacaoPj.socios"
                  :fields="[
                    { key: 'nome', label: 'Nome' },
                    { key: 'cpf', label: 'CPF' },
                    { key: 'telefone', label: 'Telefone' },
                    { key: 'cep', label: 'CEP' },
                    { key: 'acao', label: 'Ação' },
                  ]"
                  responsive
                  dark
                  striped
                  small
                >
                  <template #cell(acao)="data">
                    <button
                      class="btn btn-danger fa fa-times"
                      href="#"
                      @click="removeEntry(data, 'garantidores')"
                    ></button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="9" offset="3" class="p-0 m-0 sticky">
        <div class="footer-interno">
          <p class="footer-text-black">
            COPYRIGHT
            <b style="color: darkorange; text-decoration: underline"
              >CMR INDÚSTRIA E COMÉRCIO.</b
            >
            2023-2024
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { achaCep } from "../mixins/achaCep";
import { salvarCadastro } from "../mixins/salvarCadastro";
//import axios from 'axios'
//const { cpf, cnpj } = require('cpf-cnpj-validator');
export default {
  namespaced: true,
  name: "CadastroPj",
  mixins: [achaCep, salvarCadastro],
  computed: { ...mapState(["validacaoAuth", "cadastroPj"]) },
  data: function () {
    return {
      showReferencias: false,
      showGarantidores: false,
      showIdentificacao: true,
      showFiador: false,
      selected: "1",
      sociosAcc: 0,
      fieldsSocios: [
        { key: "nome", label: "Nome", sortable: true },
        { key: "cpf", label: "CPF" },
        { key: "rg", label: "RG" },
        { key: "profissao", label: "Profissão" },
        { key: "Action", label: "Excluir" },
      ],
      fieldsGarantidores: [
        { key: "nome", label: "Nome", sortable: true },
        { key: "cpf", label: "CPF" },
        { key: "rg", label: "RG" },
        { key: "profissao", label: "Profissão" },
        { key: "estadoCivil", label: "Estado Civil" },
        { key: "Action", label: "Excluir" },
      ],
      estadoCivilSelected: "1",
      estadoCivilSelected2: "1",
      garantidoresAcc: 0,
      socios: {
        nome: "",
        cpf: "",
        rg: "",
        email: "",
        orgaoExpeditor: "",
        profissao: "",
        nacionalidade: "1",
        estadoCivil: "",
        regimeBens: "1",
        telefone: "",
        cep: "",
        endereco: "",
        num: "",
        bairro: "",
        cidade: "",
        uf: "",
        complemento: "",
        tipoDados: "S",
        paisOrigem: "",
        docLegal: "",
        nomeConjugue: "",
        cpfConjugue: "",
        rgConjugue: "",
        orgaoExpConjugue: "",
        emailSocio: "",
        profissaoConjugue: "",
        nacionalidadeConjugue: "",
        paisOrigemConjugue: "",
        docLegalConjugue: "",
        emailConjugue: "",
      },
      garantidores: {
        nome: "",
        cpf: "",
        rg: "",
        orgaoExpeditor: "",
        profissao: "",
        nacionalidade: "1",
        estadoCivil: "",
        regimeBens: "1",
        telefone: "",
        endereco: "",
        num: "",
        complemento: "",
        bairro: "",
        cidade: "",
        email: "",
        tipoDados: "G",
        cep: "",
        docLegal: "",
        emailSocio: "",
        paisOrigem: "",
        nomeConjugue: "",
        cpfConjugue: "",
        rgConjugue: "",
        orgaoExpConjugue: "",
        profissaoConjugue: "",
        nacionalidadeConjugue: "1",
        conjugueDocLegal: "",
        conjuguePaisOrigem: "",
        emailConjugue: "",
      },
      identificacaoPj: {
        doc: "cnpj",
        razaoSocial: "",
        cnpj: "",
        inscricaoEstadual: "",
        cep: "",
        endereco: "",
        complemento: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        telefone: "",
        fax: "",
        email: "",
        regimeTributario: "1",
        socios: [],
        garantidores: [],
      },
    };
  },
  methods: {
    removeEntry(data, caller) {
      console.log("removeEntry", data, caller);

      this.identificacaoPj.socios.splice(data.index, 1);
      this.sociosAcc--;
    },

    loadAdm() {
      console.log("id", this.identificacaoPj);
      if (
        this.identificacaoPj.razaoSocial !== "" &&
        this.identificacaoPj.inscricaoEstadual !== "" &&
        this.identificacaoPj.cep !== "" &&
        this.identificacaoPj.endereco !== "" &&
        this.identificacaoPj.cidade !== "" &&
        this.identificacaoPj.bairro !== "" &&
        this.identificacaoPj.numero !== "" &&
        this.identificacaoPj.uf !== "" &&
        this.identificacaoPj.email !== "" &&
        this.identificacaoPj.telefone !== "" &&
        this.identificacaoPj.regimeTributario !== ""
      ) {
        this.identificacaoPj.cnpj = this.validacaoAuth;
        this.showIdentificacao = false;
        this.showGarantidores = false;
        this.showReferencias = true;
        this.$store.commit("setCadastroPj", this.identificacaoPj);
      } else {
        this.$toasted
          .show("Preencher Campos Obrigatórios.", {
            theme: "bubble",
            position: "top-center",
          })
          .goAway(2000);
      }
    },
    saveSocios() {
      for (let i in this.socios) {
        if (this.sociosAcc === 0 && typeof this.socios[i] !== Array) {
          this.$toasted
            .show("Preencher Campos de Sócios Obrigatórios.", {
              theme: "bubble",
              position: "top-center",
            })
            .goAway(2000);
          break;
        } else if (this.sociosAcc === 0 && typeof this.socios[i] === Array) {
          console.log("Array", this.socios[i]);
        } else if (this.sociosAcc > 0 && this.sociosAcc <= 6) {
          //console.log("regra 3");
          this.showIdentificacao = false;
          this.showGarantidores = true;
          this.showReferencias = false;
          this.showFiador = false;
        }
      }
    },
    saveGarantidores() {
      if (this.garantidoresAcc < 6 && this.nome !== "") {
        this.identificacaoPj.socios.push(
          JSON.parse(JSON.stringify(this.garantidores))
        );

        this.garantidores.nome = "";
        this.garantidores.cpf = "";
        this.garantidores.rg = "";
        this.garantidores.docLegal = "";
        this.garantidores.paisOrigem = "";
        this.garantidores.orgaoExpeditor = "";
        this.garantidores.profissao = "";
        this.garantidores.nacionalidade = "1";
        this.garantidores.estadoCivil = "";
        this.garantidores.regimeBens = "";
        this.garantidores.telefone = "";
        this.garantidores.endereco = "";
        this.garantidores.complemento = "";
        this.garantidores.bairro = "";
        this.garantidores.cidade = "";
        this.garantidores.cep = "";
        this.garantidores.nomeConjugue = "";
        this.garantidores.cpfConjugue = "";
        this.garantidores.rgConjugue = "";
        this.garantidores.conjugueProfissao = "";
        this.garantidores.conjugueDocLegal = "";
        this.garantidores.conjuguePaisOrigem = "";
        this.garantidores.emailConjugue = "";
        this.garantidores.tipoDados = "G";
        this.garantidoresAcc++;
      }
    },
    voltar(caller) {
      if (caller === "refs") {
        this.validationTwo = false;
        this.validationOne = false;
        this.showGarantidores = false;
        this.showReferencias = false;
        this.showIdentificacao = true;
        this.showFiador = false;
      } else if (caller === "garan") {
        this.validationTwo = false;
        this.validationOne = true;
        this.showGarantidores = false;
        this.showReferencias = true;
        this.showIdentificacao = false;
        this.showFiador = false;
      }
    },
    addSocios() {
      if (this.sociosAcc < 6 && this.nome !== "") {
        this.identificacaoPj.socios.push(
          JSON.parse(JSON.stringify(this.socios))
        );

        this.socios.nome = "";
        this.socios.cpf = "";
        this.socios.rg = "";
        this.socios.orgaoExpeditor = "";
        this.socios.profissao = "";
        this.socios.nacionalidade = "";
        this.socios.estadoCivil = "";
        this.socios.regimeBens = "";
        this.socios.telefone = "";
        this.socios.endereco = "";
        this.socios.num = "";
        this.socios.complemento = "";
        this.socios.bairro = "";
        this.socios.cidade = "";
        this.socios.cep = "";
        this.socios.nomeConjugue = "";
        this.socios.cpfConjugue = "";
        this.socios.rgConjugue = "";
        this.socios.conjugueOcupacao = "";
        this.socios.docLegal = "";
        this.socios.paisOrigemConjugue = "";
        this.socios.paisOrigemConjugue = "";
        this.socios.docLegalConjugue = "";
        this.socios.tipoDados = "S";
        this.sociosAcc++;
      }
    },
  },
};
</script>

<style>
h3 {
  color: #cab3a6;
}
h4 {
  color: #cab3a6 !important;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0;
}
h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.75em;
  letter-spacing: 2px;
}
.row-form {
  flex-wrap: wrap;
  display: flex;
}
.form-control {
  margin: 16px 0px;
}

.b-button-area {
  margin: 8px 0px;
}

.btnAdicionar {
  display: flex;
  /* margin-left: 61%;	Não utilizar margens/padding por porcentagem, priorizar usar propriedades do flexbox.	 */
}

.btnProximo {
  /* Display flex só é necessario ser aplicado no elemento-pai, tornado-o um flex-container. Visto que automaticamente os itens (filhos) do elemento o pai
		também herdam as propriedades do  pai(flexbox), porém como flex-itens. */
  display: flex;
  margin-left: 8%;
}
.asideVoltar {
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.footer-text-black {
  color: #353333 !important;
}
</style>
