import axios from 'axios';
export const achaCep = {
    methods:{
    
    achaEnd(data, caller) {
        let url = `https://viacep.com.br/ws/${data}/json/`;
            console.log('data', data)
        console.log('entrou chamada', caller, data)
    
        if (caller === 'id') {
            
            console.log('id')
            axios.request(url).then((el) => {
             
                this.identificacaoPj.endereco = el.data.logradouro.substr(0, 50).toUpperCase();
                this.identificacaoPj.bairro = el.data.bairro.substr(0, 35).toUpperCase();
                this.identificacaoPj.cidade = el.data.localidade.substr(0, 35).toUpperCase();
                this.identificacaoPj.uf = el.data.uf.substr(0, 2).toUpperCase();
                this.identificacaoPj.cep = data
            }).then((err) => {
                console.log(err)
            })
        } else if (caller === 'refs') {
            
            console.log('refs')

            axios.request(url).then((el) => {
           
                this.socios.endereco = el.data.logradouro.substr(0, 50).toUpperCase(); 
                this.socios.bairro = el.data.bairro.substr(0, 35).toUpperCase(); 
                this.socios.cidade = el.data.localidade.substr(0, 35).toUpperCase(); 
                this.socios.uf = el.data.uf.substr(0, 2).toUpperCase(); 
                this.socios.cep = data
            }).then((err) => {
                console.log(err)
            })
        } else if (caller === 'garan') {
            
            console.log('garan')
            axios.request(url).then((el) => {
              
                this.garantidores.endereco =  el.data.logradouro.substr(0, 50).toUpperCase();
                this.garantidores.cidade =  el.data.bairro.substr(0, 35).toUpperCase();
                this.garantidores.bairro =  el.data.localidade.substr(0, 35).toUpperCase();
                this.garantidores.uf =  el.data.uf.substr(0, 2).toUpperCase();
                this.garantidores.cep = data;
            }).then((err) => {
                console.log(err)
            })
        } else if (caller === 'sociosPf'){

            
            console.log('sociosPf')
            axios.request(url).then((el) => {
            
                this.socios.bairro =  el.data.bairro.substr(0, 35).toUpperCase();
                this.socios.uf =  el.data.uf.substr(0, 2).toUpperCase();
                this.socios.endereco =  el.data.logradouro.substr(0, 50).toUpperCase();
                this.socios.cidade =  el.data.localidade.substr(0, 35).toUpperCase();
                this.socios.cep = data
            }).then((err) => {
                console.log(err)
            })
            
        } else if(caller === 'ifPf1'){
           
            console.log('ifPf1')
            if (this.validCep.length > 0) {
              axios
                .request(url)
                .then((el) => {
          
                  this.identificacaoPf.endereco =  el.data.logradouro.substr(0, 50).toUpperCase();
                  this.identificacaoPf.bairro =  el.data.bairro.substr(0, 35).toUpperCase();
                  this.identificacaoPf.cidade =  el.data.localidade.substr(0, 35).toUpperCase();
                  this.identificacaoPf.uf =  el.data.uf.substr(0, 2).toUpperCase();
                  this.identificacaoPf.cep = data
                  
                })
                .catch((err) => {
                  console.log(err);
                });

            } else {
              this.$toasted.show("Preencher Campos Obrigatorios.", { theme: 'bubble', position: 'top-center' }).goAway(2000);
            }
        }
    },

}
}



