<template>
  <div style="height: 0">
    <b-navbar>
      <b-navbar-brand href="#"
        ><img class="logo-top" src="@/assets/LIZ_Wallpaper.png"
      /></b-navbar-brand>
      <div class="nav-text">
        <h5></h5>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  computed: { ...mapState(["headerMsg"]) },
  data() {
    return {
      msg: "Liz Lingerie Cadastro",
    };
  },
};
</script>

<style lang="scss">
.logo-top {
  max-width: 100px !important;
  position: absolute;
  top: 0px;
  right: 80px;
}
.navbar {
  height: 0;
}
.btn-info {
  background-color: #007bff !important;
  color: white !important;
  border-color: #007bff !important;
}
.nav-text {
  width: 100%;
  justify-content: center;
  text-align: center;
  h5 {
    color: white;
  }
}
</style>
