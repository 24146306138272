import axios from "axios";
import { map } from 'underscore'
import { baseApiUrl } from "../global";
export const salvarCadastro = {
    methods: {
        salvaCadastro(caller) {            
            console.log('obj request', this.identificacaoPf, this.identificacaoPj);
            if (caller === 'pf') {
                
                this.identificacaoPf.doc = 'cpf';
                
                var objRequest ={
                    socios:[],
                };
                
                objRequest.doc = "cpf",
                objRequest.nome = this.identificacaoPf.nome,
                objRequest.email = this.identificacaoPf.email,
                objRequest.cpf = this.validacaoAuth,
                objRequest.rg = this.identificacaoPf.rg,
                objRequest.orgaoExpeditor = this.identificacaoPf.orgaoExpeditor,
                objRequest.profissao = this.identificacaoPf.profissao,
                objRequest.nacionalidade = this.identificacaoPf.nacionalidade,
                objRequest.nacionalidadePaisOrigem = this.identificacaoPf.paisOrigem,
                objRequest.nacionalidadeDocLegal = this.identificacaoPf.docLegal,
                objRequest.estadoCivil = this.identificacaoPf.estadoCivil,
                objRequest.regimeBens = this.identificacaoPf.regimeBens,
                objRequest.telefone = this.identificacaoPf.telefone,
                objRequest.uf = this.identificacaoPf.uf,
                objRequest.cep = this.identificacaoPf.cep,
                objRequest.endereco = this.identificacaoPf.endereco,
                objRequest.bairro = this.identificacaoPf.bairro,
                objRequest.cidade = this.identificacaoPf.cidade,
                objRequest.numero = this.identificacaoPf.num,
                objRequest.complemento = this.identificacaoPf.complemento,
                objRequest.nomeConjugue = this.identificacaoPf.nomeConjugue,
                objRequest.cpfConjugue = this.identificacaoPf.cpfConjugue,
                objRequest.rgConjugue = this.identificacaoPf.rgConjugue,
                objRequest.profissaoConjugue = this.identificacaoPf.profissaoConjugue,
                objRequest.nacionalidadeConjugue = this.identificacaoPf.nacionalidadeConjugue,
                objRequest.conjugeDocLegal = this.identificacaoPf.conjugueDocLegal,
                objRequest.conjuguePaisOrigem = this.identificacaoPf.conjuguePaisOrigem,
                objRequest.TelConjugue = this.identificacaoPf.TelConjugue;
                objRequest.emailConjugue = this.identificacaoPf.emailConjugue,
               
                
                
                objRequest.socios = map(this.identificacaoPf.socios, el => {
                    return el
                })               
                console.log('obj request pf', this.identificacaoPf)
                console.log('mixin on pf', objRequest);
                this.identificacaoPf.doc = "cpf";
                this.identificacaoPf.cpf = this.validacaoAuth;
                this.$store.commit("setCadastroPf", Object.assign(objRequest));                
                try {
                    axios.post(`${baseApiUrl}/Franqueado/SaveFranqueado`, objRequest).then(resp => {                      
                        console.log('entrou try', resp)
                        if(resp){
                            if (resp.data.errocode === 1 || resp.data.errocode === '1') {      
                            console.log("entrei no");                     
                            this.$store.commit('setShowMsgSuccess', true)
                            console.log('if axios entrou', this.$store.state.showMsgSuccess)
                            this.$store.state.showCadastroPf = false;
                            this.$store.state.showCadastroPj = false;
                            this.$store.state.showMsgSuccess = true;
                            this.$toasted.show('Gravação Realizada com Sucesso.', {  theme: 'bubble',
                            position: 'top-center',
                            duration: 5000 })
                            document.querySelector('.content').classList.add('contentSuccess')
                            console.log('refs', this.$refs.containerContent)
                        }
                        else if (resp.data.errorcode !== 1 || resp.data.errorcode !== '1') {
                            console.log('resp erro', resp)
                            this.$toasted.show('erro: Impossivel realizar a gravação, verifique se todos os campos foram preenchidos corretamente.', {
                                theme: 'bubble',
                                position: 'top-center',
                                duration: 5000
                            })
                        }
                        }
                        
                    }).catch((err) => {
                        
                         this.$toasted.show(err, {
                            theme: 'bubble',
                            position: 'top-center',
                            duration: 5000,
                            type: 'success',
                        })
                    })
                } catch (error) {
                    console.log('catch try', error)
                }
                // setTimeout(function(){
                //     this.$refs.formCad.submit();
                // }, 8000)
            }
            else if (caller === 'pj') {
                this.identificacaoPj.doc = 'cnpj'


                
                let objRequest = {
                    socios:[],
                };

                objRequest.doc = "cnpj";
                objRequest.nome = this.identificacaoPj.razaoSocial,
                objRequest.email = this.identificacaoPj.email,
                objRequest.cnpj = this.validacaoAuth,
                objRequest.uf = this.identificacaoPj.uf,
                objRequest.fax = this.identificacaoPj.fax;
                objRequest.rg = this.identificacaoPj.rg,
                objRequest.orgaoExpeditor = this.identificacaoPj.orgaoExpeditor,
                objRequest.profissao = this.identificacaoPj.profissao,
                objRequest.nacionalidade = this.identificacaoPj.nacionalidade,
                objRequest.nacionalidadePaisOrigem = this.identificacaoPj.nacionalidadePaisOrigem,
                objRequest.nacionalidadeDocLegal = this.identificacaoPj.nacionalidadeDocLegal,
                objRequest.estadoCivil = this.identificacaoPj.estadoCivil,
                objRequest.regimeBens = this.identificacaoPj.regimeBens,
                objRequest.regimeTributario = this.identificacaoPj.regimeTributario,
                objRequest.inscricaoEstadual = this.identificacaoPj.inscricaoEstadual
                objRequest.telefone = this.identificacaoPj.telefone,
                objRequest.cep = this.identificacaoPj.cep,
                objRequest.endereco = this.identificacaoPj.endereco,
                objRequest.bairro = this.identificacaoPj.bairro,
                objRequest.cidade = this.identificacaoPj.cidade,
                objRequest.numero = this.identificacaoPj.numero,
                objRequest.complemento = this.identificacaoPj.complemento,
                objRequest.socios = map(this.identificacaoPj.socios, e => { return e })
               

                console.log('cnpj', objRequest, objRequest.razaoSocial)
                this.$store.commit("setCadastroPj", Object.assign(objRequest));
                console.log('caller pj entrou')
                
                try {
                    axios.post(`${baseApiUrl}/Franqueado/saveFranqueado`, objRequest).then(resp => {
                        console.log('resp api gravação', resp)
                        if(resp){
                        if (resp.data.errocode === 1 || resp.data.errocode === '1') {
                            console.log('entrou aqui no code 1');
                            this.$store.commit('setShowMsgSuccess', true)
                            console.log(this.$store.state.showMsgSuccess)
                            this.$store.state.showCadastroPf = false;
                            this.$store.state.showCadastroPj = false;
                            this.$store.state.showMsgSuccess = true;
                            document.querySelector('.content').classList.add('contentSuccess')
                            console.log('refs', this.identificacaoPj, this.$refs.containerContent)
                        } else if (resp.data.errocode !== '1' || resp.data.errocode !== 1) {
                            console.log('erro else if', resp)
                            this.$toasted.show('Erro: Verifique as informações digitadas e tente novamente.', {
                                theme: 'bubble',
                                position: 'top-center',
                                duration: 5000
                            })
                        }
                    }
                }).catch((err) => {
                        this.$toasted.show(err, {
                            theme: 'bubble',
                            position: 'top-center',
                            duration: 5000
                        })
                    })
                } catch (er) {
                    this.$toasted.show(er, {
                        theme: 'bubble',
                        position: 'top-center',
                        duration: 5000
                    })

                }
                console.log('mixin on pj', this.identificacaoPj);
                setTimeout(function(){
                    this.$refs.formCad.submit();
                }, 8000)
            }
        },
        arrumaObj(){

        }
    }
}

/* MODELO DE GRAVACAO DA API ****** 
 

PF

 identificacaoPf: Object {
    doc: "cpf" string 5
    nome: String 30
    email: String 35
    cpf: number  11
    rg: number 15
    orgaoExpeditor: String 6
    profissao: string 30
    nacionalidade: String 15
    nacionalidadePaisOrigem: String 30
    nacionalidadeDocLegal: String 30
    estadoCivil String 15
    regimeBens: String 15
    telefone: Number 15
    cep: Number 11
    endereco: String 50
    bairro: String 35
    cidade: String 35
    numero: number 8
    complemento: String 30
    nomeConjugue: String 30
    cpfConjugue: Number 11
    rgConjugue: Number 15
    profissaoConjugue: string 30
    nacionalidadeConjugue: string 15
    emailConjugue: string 35
    socios[{
        nome: String 30
    email: String 35
    cpf: number  11
    rg: number 15
    orgaoExpeditor: String 6
    profissao: string 30
    nacionalidade: String 15
    nacionalidadePaisOrigem: String 30
    nacionalidadeDocLegal: String 30
    estadoCivil String 15
    regimeBens: String 15
    telefone: Number 15
    cep: Number 11
    endereco: String 50
    bairro: String 35
    cidade: String 35
    numero: number 8
    complemento: String 30
    }]
 }
 
 
 
 PJ
 
 identificacaoPj: Object {
    doc: "cnpj" string 5
    nome: String 30
    email: String 35
    cnpj: number  11
    rg: number 15
    orgaoExpeditor: String 6
    profissao: string 30
    nacionalidade: String 15
    nacionalidadePaisOrigem: String 30
    nacionalidadeDocLegal: String 30
    estadoCivil String 15
    regimeBens: String 15
    telefone: Number 15
    cep: Number 11
    endereco: String 50
    bairro: String 35
    cidade: String 35
    numero: number 8
    complemento: String 30
    
    socios[{
        nome: String 30
    email: String 35
    cpf: number  11
    rg: number 15
    orgaoExpeditor: String 6
    profissao: string 30
    nacionalidade: String 15
    nacionalidadePaisOrigem: String 30
    nacionalidadeDocLegal: String 30
    estadoCivil String 15
    regimeBens: String 15
    telefone: Number 15
    cep: Number 11
    endereco: String 50
    bairro: String 35
    cidade: String 35
    numero: number 8
    complemento: String 30
    nomeConjugue: String 30
    cpfConjugue: Number 11
    rgConjugue: Number 15
    profissaoConjugue: string 30
    nacionalidadeConjugue: string 15
    emailConjugue: string 35
    }]
    garantidores:[{
            nome: String 30
    email: String 35
    cpf: number  11
    rg: number 15
    orgaoExpeditor: String 6
    profissao: string 30
    nacionalidade: String 15
    nacionalidadePaisOrigem: String 30
    nacionalidadeDocLegal: String 30
    estadoCivil String 15
    regimeBens: String 15
    telefone: Number 15
    cep: Number 11
    endereco: String 50
    bairro: String 35
    cidade: String 35
    numero: number 8
    complemento: String 30
    nomeConjugue: String 30
    cpfConjugue: Number 11
    rgConjugue: Number 15
    profissaoConjugue: string 30
    nacionalidadeConjugue: string 15
    emailConjugue: string 35
    }]
 }
 {
  "doc": "cnpj",
  "cnpj": "AMANDA A OLIVEIRA",
  "inscricaoEstadual": "34399013000105",
  "regimeTributario": "123123123",
  "fax": "13231460",
  "Email": "RUA PRESIDENTE JUSCELINO ",
  "nome": "12312",
  "cep": "RESIDENCIAL MONTE ALEGRE",
  "endereco": "CAMPO LIMPO PAULISTA",
  "bairro": "SP",
  "cidade": "123123123",
  "numero": "",
  "uf": "RICARDO.PAULUSSI@GMAIL.COM",
  "telefone": "SIMPLES NACIONAL",
  "nacionalidadePaisOrigem": "SIMPLES NACIONAL",
  "nacionalidadeDocLegal": "SIMPLES NACIONAL",
  "socios": [
    {
      "STCD1": "34399013000105",
      "nome": "AMELINDA",
      "cpf": "980201745",
      "rg": "368410153",
      "email": "",
      "orgaoExpeditor": "SSPSP",
      "profissao": "DEV",
      "nacionalidade": "BRASILEIRA",
      "estadoCivil": "SOLTEIRO",
      "regimeBens": "1",
      "telefone": "12311233",
      "cep": "13231460",
      "endereco": "RUA PRESIDENTE JUSCELINO ",
      "num": "13123",
      "bairro": "RESIDENCIAL MONTE ALEGRE",
      "cidade": "CAMPO LIMPO PAULISTA",
      "uf": "SP",
      "complemento": "CASA",
      "tipoDados": "G",
      "paisOrigem": "",
      "docLegal": "",
      "nomeConjugue": "",
      "cpfConjugue": "",
      "rgConjugue": "",
      "profissaoConjugue": "",
      "nacionalidadeConjugue": "",
      "paisOrigemConjugue": "",
      "docLegalConjugue": ""
    },
    {
      "STCD1": "34399013000105",
      "nome": "AMELINDA",
      "cpf": "980201745",
      "rg": "368410153",
      "email": "",
      "orgaoExpeditor": "SSPSP",
      "profissao": "DEV",
      "nacionalidade": "BRASILEIRA",
      "estadoCivil": "SOLTEIRO",
      "regimeBens": "1",
      "telefone": "12311233",
      "cep": "13231460",
      "endereco": "RUA PRESIDENTE JUSCELINO ",
      "num": "13123",
      "bairro": "RESIDENCIAL MONTE ALEGRE",
      "cidade": "CAMPO LIMPO PAULISTA",
      "uf": "SP",
      "complemento": "CASA",
      "tipoDados": "G",
      "paisOrigem": "",
      "docLegal": "",
      "nomeConjugue": "",
      "cpfConjugue": "",
      "rgConjugue": "",
      "profissaoConjugue": "",
      "nacionalidadeConjugue": "",
      "paisOrigemConjugue": "",
      "docLegalConjugue": ""
    },
    {
      "STCD1": "34399013000105",
      "nome": "AMELINDA",
      "cpf": "980201745",
      "rg": "368410153",
      "email": "",
      "orgaoExpeditor": "SSPSP",
      "profissao": "DEV",
      "nacionalidade": "BRASILEIRA",
      "estadoCivil": "SOLTEIRO",
      "regimeBens": "1",
      "telefone": "12311233",
      "cep": "13231460",
      "endereco": "RUA PRESIDENTE JUSCELINO ",
      "num": "13123",
      "bairro": "RESIDENCIAL MONTE ALEGRE",
      "cidade": "CAMPO LIMPO PAULISTA",
      "uf": "SP",
      "complemento": "CASA",
      "tipoDados": "G",
      "paisOrigem": "",
      "docLegal": "",
      "nomeConjugue": "",
      "cpfConjugue": "",
      "rgConjugue": "",
      "profissaoConjugue": "",
      "nacionalidadeConjugue": "",
      "paisOrigemConjugue": "",
      "docLegalConjugue": ""
    },
    {
      "STCD1": "34399013000105",
      "nome": "AMELINDA",
      "cpf": "980201745",
      "rg": "368410153",
      "email": "",
      "orgaoExpeditor": "SSPSP",
      "profissao": "DEV",
      "nacionalidade": "BRASILEIRA",
      "estadoCivil": "SOLTEIRO",
      "regimeBens": "1",
      "telefone": "12311233",
      "cep": "13231460",
      "endereco": "RUA PRESIDENTE JUSCELINO ",
      "num": "13123",
      "bairro": "RESIDENCIAL MONTE ALEGRE",
      "cidade": "CAMPO LIMPO PAULISTA",
      "uf": "SP",
      "complemento": "CASA",
      "tipoDados": "G",
      "paisOrigem": "",
      "docLegal": "",
      "nomeConjugue": "",
      "cpfConjugue": "",
      "rgConjugue": "",
      "profissaoConjugue": "",
      "nacionalidadeConjugue": "",
      "paisOrigemConjugue": "",
      "docLegalConjugue": ""
    },
    {
      "STCD1": "34399013000105",
      "nome": "AMELINDA",
      "cpf": "980201745",
      "rg": "368410153",
      "email": "",
      "orgaoExpeditor": "SSPSP",
      "profissao": "DEV",
      "nacionalidade": "BRASILEIRA",
      "estadoCivil": "SOLTEIRO",
      "regimeBens": "1",
      "telefone": "12311233",
      "cep": "13231460",
      "endereco": "RUA PRESIDENTE JUSCELINO ",
      "num": "13123",
      "bairro": "RESIDENCIAL MONTE ALEGRE",
      "cidade": "CAMPO LIMPO PAULISTA",
      "uf": "SP",
      "complemento": "CASA",
      "tipoDados": "G",
      "paisOrigem": "",
      "docLegal": "",
      "nomeConjugue": "",
      "cpfConjugue": "",
      "rgConjugue": "",
      "profissaoConjugue": "",
      "nacionalidadeConjugue": "",
      "paisOrigemConjugue": "",
      "docLegalConjugue": ""
    },
    {
      "STCD1": "34399013000105",
      "nome": "AMELINDA",
      "cpf": "980201745",
      "rg": "368410153",
      "email": "",
      "orgaoExpeditor": "SSPSP",
      "profissao": "DEV",
      "nacionalidade": "BRASILEIRA",
      "estadoCivil": "SOLTEIRO",
      "regimeBens": "1",
      "telefone": "12311233",
      "cep": "13231460",
      "endereco": "RUA PRESIDENTE JUSCELINO ",
      "num": "13123",
      "bairro": "RESIDENCIAL MONTE ALEGRE",
      "cidade": "CAMPO LIMPO PAULISTA",
      "uf": "SP",
      "complemento": "CASA",
      "tipoDados": "G",
      "paisOrigem": "",
      "docLegal": "",
      "nomeConjugue": "",
      "cpfConjugue": "",
      "rgConjugue": "",
      "profissaoConjugue": "",
      "nacionalidadeConjugue": "",
      "paisOrigemConjugue": "",
      "docLegalConjugue": ""
    }
 ]
}
 */
